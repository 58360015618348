import { useState } from 'react';
import styled from 'styled-components';
import { useField } from 'formik';
import { Stack } from '@tymate/margaret';
import { FormField, FormLabel, Input, ErrorMessageWrapper } from 'ui/forms';
import { uniqueId } from 'lodash';

// https://css-tricks.com/the-cleanest-trick-for-autogrowing-textareas/
const GrowWrap = styled.div`
  display: grid;
  overflow: hidden;
  width: 100%;
  min-height: 100px;

  &:after {
    content: attr(data-replicated-value) ' ';
    white-space: pre-wrap;
    visibility: hidden;
  }

  > textarea {
    resize: none;
  }

  > textarea,
  &:after {
    width: 100%;
    max-height: ${({ maxHeight }) => maxHeight || '200px'};
    overflow: auto;
    border: 1px solid ${({ theme }) => theme.separator};
    padding: 0.5rem;
    font: inherit;
    grid-area: 1 / 1 / 2 / 2;
  }
`;

const TextareaField = ({ label, rightLabel, maxHeight, ...props }) => {
  const [field, { touched, error }] = useField(props);
  const [id] = useState(uniqueId());
  const hasError = Boolean(touched) && Boolean(error);

  return (
    <FormField>
      <Stack alignX="space-between" alignY="center">
        {label && <FormLabel htmlFor={id}>{label}</FormLabel>}
        {rightLabel && rightLabel}
      </Stack>
      <Stack style={{ position: 'relative' }}>
        <GrowWrap data-replicated-value={field.value} maxHeight={maxHeight}>
          <Input
            as="textarea"
            {...field}
            {...props}
            id={id}
            hasError={hasError}
          />
        </GrowWrap>
        {hasError && <ErrorMessageWrapper>{error}</ErrorMessageWrapper>}
      </Stack>
    </FormField>
  );
};

export default TextareaField;
