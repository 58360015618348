import { Main, Wrapper } from 'ui';
import { OrganizationHeader, TeamItem } from 'components';
import { useApp } from 'hooks';
import { useQuery } from '@apollo/client';
import { GET_TEAM_PRESENTATIONS } from 'api';
import { Stack, Text, Spinner } from '@tymate/margaret';
import BackButton from 'components/BackButton';
import { useTranslation } from 'react-i18next';

const Team = () => {
  const { organization } = useApp();
  const { t } = useTranslation('home');

  const { data: teamPresentationData, loading } = useQuery(
    GET_TEAM_PRESENTATIONS,
  );

  const presentations =
    teamPresentationData?.currentOrganization?.presentations?.edges || [];

  return (
    <>
      <OrganizationHeader
        backgroundImage={organization?.banner?.url}
        organization={organization}
        withText
      />

      <Main>
        <Wrapper>
          <Stack gutterSize={1.5} direction="column">
            <Stack>
              <BackButton />
            </Stack>
            <Text type="h1">{t('team.title')}</Text>
            {loading ? (
              <Spinner />
            ) : (
              presentations.map(({ node }) => (
                <TeamItem key={node?.id} presentation={node} />
              ))
            )}
          </Stack>
        </Wrapper>
      </Main>
    </>
  );
};

export default Team;
