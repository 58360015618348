import React from 'react';
import { Button, ButtonReset, Stack, Text } from '@tymate/margaret';
import { MiniCover, Tag } from 'ui';
import ReactModal from 'react-modal';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import ProfileCard from 'components/ProfilCard';
import { MdClose } from 'react-icons/md';

const Modal = styled(ReactModal)`
  position: absolute;
  z-index: 2147483646;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  margin-right: -50%;
  padding: 16px;
  max-height: calc(100vh - 32px);
  border: 0;
  overflow-y: auto;
  background: #1a1a1a;
  border-radius: ${({ theme }) => theme.modal.borderRadius};
  width: ${({ theme }) => theme.modal?.sizes?.default || '500px'};
  max-width: calc(100vw - 32px);
`;

const DetailsModal = ({ isOpen, onRequestClose, audio, isChapter }) => {
  const { t } = useTranslation('podcastDetail');

  const overlayStyles = {
    zIndex: 2147483646,
    backgroundColor: 'rgba(0,0,0, 0.7)',
  };

  const tags = audio?.podcast?.tags?.edges || [];
  const contributors = audio?.contributors?.edges || [];

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={{ overlay: overlayStyles }}
    >
      <Stack size="full" alignX="flex-end" paddingBottom={2}>
        <ButtonReset onClick={onRequestClose}>
          <MdClose />
        </ButtonReset>
      </Stack>
      <Stack alignX="center" direction="column" gap={1}>
        {!isChapter && (
          <MiniCover
            src={audio?.cover?.url ?? audio?.podcast?.image?.url}
            size={96}
          />
        )}

        <div>
          <Text type="h3" fontWeight={700} style={{ textAlign: 'center' }}>
            {audio?.title}
          </Text>
        </div>
        <Text
          color="primary"
          marginTop={0.5}
          as={Button}
          onClick={onRequestClose}
        >
          {audio?.podcast?.title}
        </Text>
      </Stack>
      <div
        style={{ color: 'rgba(255, 255, 255, 0.38)' }}
        dangerouslySetInnerHTML={{ __html: audio?.description }}
      />
      {tags?.length > 0 && (
        <Stack direction="column" paddingBottom={1} paddingTop={1}>
          <Text type="body" paddingTop={1} fontWeight={700}>
            {t('tags')}
          </Text>
          <Stack gap={1} paddingTop={0.5}>
            {(tags ?? []).map(({ node }) => (
              <Tag key={node?.id}>{node?.name}</Tag>
            ))}
          </Stack>
        </Stack>
      )}
      {contributors?.length > 0 && (
        <>
          <Text type="body" paddingTop={1} fontWeight={700}>
            {t('contributors')}
          </Text>
          {contributors.map(({ node }) => (
            <div
              style={{
                marginTop: 10,
              }}
            >
              <ProfileCard
                lastName={node?.contributorable?.lastName}
                firstName={node?.contributorable?.firstName}
                avatar={node?.contributorable?.avatar?.url}
              />
            </div>
          ))}
        </>
      )}
    </Modal>
  );
};

export default DetailsModal;
