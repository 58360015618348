import { login } from 'api/auth';
import { Stack, Text } from '@tymate/margaret';
import { Button, Layout } from 'components';
import { useTranslation } from 'react-i18next';
import { TextField } from 'components/Fields';
import { useAuth } from 'hooks';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Navigate, useLocation } from 'react-router-dom';
import { formatRestError } from 'utils';
import { ErrorMessage } from 'ui/forms';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Password = () => {
  const { t } = useTranslation(['login', 'errors']);
  const { onUpdate, accessToken } = useAuth();

  const location = useLocation();

  const email = location?.state.email;

  const handleLogin = async (values, { setStatus }) => {
    try {
      const { data } = await login({ email: email, ...values });
      onUpdate(data);
    } catch (err) {
      setStatus(t(`errors:${formatRestError(err)}`));
    }
  };

  if (Boolean(accessToken)) {
    return <Navigate to="/" />;
  }

  return (
    <Layout size="tiny">
      <Stack alignX="center" direction="column" gutterSize={2} size="full">
        <div>
          <Text type="h1" style={{ textAlign: 'center' }}>
            {email}
          </Text>
        </div>
        <div>
          <Text
            type="body"
            fontWeight="bold"
            style={{
              textAlign: 'center',
              opacity: 0.6,
            }}
          >
            {t('passwordInfo')}
          </Text>
        </div>

        <Formik
          initialValues={{ password: '' }}
          validationSchema={Yup.object().shape({
            password: Yup.string().required(t('errors:required')),
          })}
          onSubmit={handleLogin}
        >
          {({ isSubmitting, status }) => (
            <Form style={{ width: '100%' }}>
              <Stack direction="column" alignX="center" gap={2}>
                <TextField
                  id="login-password"
                  type="password"
                  label={t('password')}
                  labelSuffix={
                    <Button
                      variant="link"
                      to="/lost-access"
                      type="button"
                      style={{ color: 'var(--ui-primary)', fontWeight: 400 }}
                    >
                      {t('forgottenPassword')}
                    </Button>
                  }
                  name="password"
                  autoComplete="off"
                />
                {Boolean(status) && <ErrorMessage>{status}</ErrorMessage>}

                <Button variant="primary" size="full" isLoading={isSubmitting}>
                  {t('login')}
                </Button>
              </Stack>
            </Form>
          )}
        </Formik>

        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </Stack>
    </Layout>
  );
};

export default Password;
