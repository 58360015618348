const CheckboUnselected = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 28 28"
      fill="none"
    >
      <rect
        x="3.625"
        y="3.625"
        width="20.75"
        height="20.75"
        rx="3.75"
        stroke="#A9AFC3"
        stroke-width="1.5"
      />
    </svg>
  );
};

export default CheckboUnselected;
