import { LightWrapper, MainLight } from 'ui';
import { Header } from 'components';
import { useApp } from 'hooks';
import { Stack, Text, Spinner } from '@tymate/margaret';
import { useTranslation } from 'react-i18next';
import { GET_ADMIN_CATEGORY_PODCASTS } from 'api';
import { useQuery } from '@apollo/client';
import styled from 'styled-components';
import AdminCard from 'components/AdminCard';

const PodcastsWrapper = styled(Stack).attrs({ size: 'full', paddingTop: 1 })`
  display: grid;
  column-gap: 20px;
  row-gap: 20px;
  grid-template-columns: repeat(2, 1fr);

  ${({ theme }) => theme.media.tablet`
    grid-template-columns: repeat(5, 1fr);
  `};

  ${({ theme }) => theme.media.desktop`
    grid-template-columns: repeat(7, 1fr);
  `};
`;

const Admin = () => {
  const { organization } = useApp();
  const { t } = useTranslation('admin');

  const { data: internalProductionData, loading: loadingInternalProduction } =
    useQuery(GET_ADMIN_CATEGORY_PODCASTS, {
      variables: {
        slug: 'internal_production',
        first: 100,
      },
    });
  const internalProductionCategory =
    internalProductionData?.category?.podcasts?.edges || [];

  const { data: audioLearningData, loading: loadingAudioLearning } = useQuery(
    GET_ADMIN_CATEGORY_PODCASTS,
    {
      variables: {
        slug: 'audio_learning',
        first: 100,
      },
    },
  );
  const audioLearningCategory =
    audioLearningData?.category?.podcasts?.edges || [];

  const { data: actualitiesPodcasts, loading: loadingActualities } = useQuery(
    GET_ADMIN_CATEGORY_PODCASTS,
    {
      variables: {
        slug: 'actualities',
        first: 100,
      },
    },
  );
  const podcastCategory = actualitiesPodcasts?.category?.podcasts?.edges || [];

  return (
    <>
      <Header customLogo={organization?.logo?.url} color="#060d0d" isAdmin />

      <MainLight>
        <LightWrapper>
          <Stack direction="column">
            <Text type="h1" color="textDark">
              {t('title')}
            </Text>

            <Text type="h2" color="textDark" paddingTop={1}>
              {t('news')}
            </Text>

            {loadingActualities ? (
              <Spinner />
            ) : (
              <PodcastsWrapper>
                {podcastCategory.map(({ node }) => (
                  <Stack size="full" direction="column" key={node?.id}>
                    <AdminCard
                      backgroundImage={node?.image?.url}
                      title={node?.title}
                      withTitle
                      square
                      to={`/admin/${node?.id}`}
                    />
                  </Stack>
                ))}
              </PodcastsWrapper>
            )}

            <Text type="h2" color="textDark" paddingTop={1}>
              {t('podcasts')}
            </Text>

            {loadingInternalProduction ? (
              <Spinner />
            ) : (
              <PodcastsWrapper>
                {internalProductionCategory.map(({ node }) => (
                  <Stack size="full" direction="column" key={node?.id}>
                    <AdminCard
                      backgroundImage={node?.image?.url}
                      title={node?.title}
                      withTitle
                      square
                      to={`/admin/${node?.id}`}
                      kind={node?.kind}
                    />
                  </Stack>
                ))}
              </PodcastsWrapper>
            )}

            <Text type="h2" color="textDark" paddingTop={1}>
              {t('audioLearnings')}
            </Text>
            {loadingAudioLearning ? (
              <Spinner />
            ) : (
              <PodcastsWrapper>
                {audioLearningCategory.map(({ node }) => (
                  <Stack size="full" direction="column" key={node?.id}>
                    <AdminCard
                      backgroundImage={node?.image?.url}
                      title={node?.title}
                      withTitle
                      square
                      to={`/admin/${node?.id}`}
                    />
                  </Stack>
                ))}
              </PodcastsWrapper>
            )}
          </Stack>
        </LightWrapper>
      </MainLight>
    </>
  );
};

export default Admin;
