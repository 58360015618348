import React from 'react';
import { ButtonReset, Stack, Text } from '@tymate/margaret';
import ReactModal from 'react-modal';
import styled from 'styled-components';
//import { MdClose } from 'react-icons/md';
import Close from './Icons/Close';
import { useTranslation } from 'react-i18next';
import Button from './Button';

const Modal = styled(ReactModal)`
  position: absolute;
  z-index: 2147483646;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  margin-right: -50%;
  padding: 16px;
  max-height: calc(100vh - 32px);
  border: 0;
  overflow-y: auto;
  background: #fff;
  border-radius: ${({ theme }) => theme.modal.borderRadius};
  width: ${({ theme }) => theme.modal?.sizes?.default || '500px'};
  max-width: calc(100vw - 32px);
`;

const DeleteModal = ({ isOpen, onRequestClose, onDelete }) => {
  const { t } = useTranslation('adminPodcast');

  const overlayStyles = {
    zIndex: 2147483646,
    backgroundColor: 'rgba(0,0,0, 0.7)',
  };

  const onConfirm = async () => {
    await onDelete();
    onRequestClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={{ overlay: overlayStyles }}
    >
      <Stack size="full" alignX="flex-end" paddingBottom={2}>
        <ButtonReset onClick={onRequestClose}>
          <Close />
        </ButtonReset>
      </Stack>
      <Stack alignX="center" direction="column" gap={2} paddingBottom={2}>
        <Text type="h1" color="textDark" style={{ textAlign: 'center' }}>
          {t('deleteMessage')}
        </Text>
        <Stack gap={1}>
          <Button
            variant="adminPrimary"
            onClick={() => {
              onRequestClose();
            }}
          >
            {t('cancel')}
          </Button>
          <Button variant="adminSecondary" onClick={onConfirm}>
            {t('confirm')}
          </Button>
        </Stack>
      </Stack>
    </Modal>
  );
};

export default DeleteModal;
