export const colors = {
  palette: {
    cyan: {
      50: '#E8FCFC',
      100: '#C0F7F6',
      200: '#97F2EF',
      300: '#79EEEB', // Accent 1
      400: '#45E7E3',
      500: '#1DE2DD',
      600: '#17B5B1',
      700: '#118885',
      800: '#0B5B58',
      900: '#062D2C',
    },
    blue: {
      50: '#E9EFFB',
      100: '#C2D3F4',
      200: '#9BB7ED',
      300: '#749BE6',
      400: '#5081E0', // Accent 2
      500: '#2663D9',
      600: '#1F4FAD',
      700: '#173B82',
      800: '#0F2857',
      900: '#08142B',
    },
    red: {
      50: '#FCE8EC',
      100: '#F7BFCA',
      200: '#F396A8',
      300: '#EE6D85',
      400: '#E94463',
      500: '#E41B41',
      600: '#D2193C', // Accent 3
      700: '#891027',
      800: '#5B0B1A',
      900: '#2E050D',
    },
  },
  ui: {
    primary: 'var(--colors-cyan-300)',
    primaryLight: '#61BEBC',
    secondary: 'var(--colors-blue-400)',
    tertiary: 'var(--colors-red-600)',
    favorite: '#FFBF00',

    backgroundTag: 'rgba(0, 170, 255, 0.16)',

    background: '#001b29',
    backgroundWhite: '#ffffff',
    backgroundPopover: '#012e44',
    backgroundEmbed: 'linear-gradient(180deg, #DAF2F2 0%, #F7F7F7 41.15%)',
    backgroundTextCode: '#00141f',
    backgroundPlayer: 'rgba(1, 46, 68, 1)',
    backgroundTeamCard: 'rgba(255, 255, 255, 0.08)',
    playerBorder: 'rgba(255, 255, 255, 0.16)',
    backgroundLight: 'rgba(0, 170, 255, 0.5)',
    backgroundDisabled: '#AAAAAA',
    backgroundGradient:
      'linear-gradient(167.96deg, rgba(0, 170, 255, 0.5) 0%, rgba(0, 0, 0, 0) 100%), #000000',

    text: '#fff',
    textLight: 'rgba(255, 255, 255, 0.38)',
    textDark: 'rgba(0, 0, 0, 0.87)',
    textGrey: 'rgba(0, 0, 0, 0.5)',
    textCallout: 'rgba(255, 255, 255, 0.6)',
    textLightOnLightTheme: 'rgba(0, 0, 0, 0.6)',
    textLighterOnLightTheme: 'rgba(0, 0, 0, 0.38)',

    error: '#F96E61',
    success: '#79EEEB',
    separator: 'rgba(255, 255, 255, 0.16)',

    inputFocusBorder: 'rgba(255, 255, 255, 0.38)',
    inputFocusBoxShadow: '0px 0px 4px rgba(121, 238, 235, 0.5)',
    inputDisabledBackground: 'rgba(0, 170, 255, 0.08), #000',
    inputDisabledColor: 'rgba(255, 255, 255, 0.38)',
    inputDisabledBorder: 'rgba(255, 255, 255, 0.16)',

    adminPrimary: 'rgba(210, 25, 60, 1)',
    adminPublished: 'rgba(73, 180, 109, 1)',
    adminPlanned: 'rgba(255, 191, 0, 1)',
    adminDraft: 'rgba(99, 122, 121, 1)',
  },
};

export const theme = {
  mainNav: {
    position: 'top',
    width: '25rem',
    maxWidth: '100vw',
    widthTablet: '16rem',
    height: '68px',
    offCanvasToVisibleBreakpoint: 'tablet',
    seperator: 'rgba(255, 255, 255, 0.16)',
    triggerColor: {
      expanded: 'var(--ui-text)',
      collapsed: 'var(--ui-text)',
    },
  },
  button: {
    paddingHorizontal: '8px',
    paddingVertical: '16px',
    fontSize: '18px',
    fontWeight: 'bold',
    borderRadius: '100px',
    alignY: 'center',
    primary: {
      minWidth: '327px',
      background: 'var(--ui-primary)',
      color: 'var(--ui-text-dark)',
      justifyContent: 'center',
      backgroundHover: 'var(--ui-primary-light)',
      backgroundDisabled: '#aaaaaa',
      colorDisabled: 'rgba(0, 0, 0, 0.6)',
      boxShadowFocus: '0 0 0 4px rgba(121, 238, 235, 0.38)',
    },
    embed: {
      paddingHorizontal: '16px',
      paddingVertical: '8px',
      background: 'var(--ui-primary)',
      color: 'var(--ui-text-dark)',
      justifyContent: 'center',
      backgroundHover: 'var(--ui-primary-light)',
      backgroundDisabled: '#aaaaaa',
      colorDisabled: 'rgba(0, 0, 0, 0.6)',
      boxShadowFocus: '0 0 0 4px rgba(121, 238, 235, 0.38)',
    },
    outline: {
      paddingHorizontal: '28px',
      paddingVertical: '14px',
      borderRadius: '20px',
      color: 'var(--ui-text)',
      background: 'transparent',
      boxShadow: '0 0 0 1px var(--ui-text)',
      backgroundHover: 'rgba(255, 255, 255, 0.08)',
      boxShadowFocus: '0 0 0 1px var(--ui-text),0 0 8px #79EEEB',
    },
    simple: {
      color: 'var(--ui-primary)',
      background: 'transparent',
      paddingVertical: '2px',
      paddingHorizontal: '2px',
      fontWeight: 'normal',
    },
    text: {
      color: 'var(--ui-text)',
      background: 'transparent',
      fontWeight: 'normal',
      paddingHorizontal: '0px',
    },

    adminPrimary: {
      background: 'var(--ui-admin-primary)',
      color: 'var(--ui-text)',
      justifyContent: 'center',
      backgroundHover: 'var(--ui-admin-primary)',
      backgroundDisabled: '#aaaaaa',
      colorDisabled: 'rgba(0, 0, 0, 0.6)',
      boxShadowFocus: '0 0 0 4px rgba(210, 25, 60, .38)',
      borderRadius: '10px',
      paddingHorizontal: '16px',
    },
    adminSecondary: {
      background: 'var(--ui-transparent)',
      color: 'var(--ui-admin-primary)',
      justifyContent: 'center',
      backgroundHover: 'rgba(210, 25, 60, .1)',
      backgroundDisabled: '#aaaaaa',
      colorDisabled: 'rgba(0, 0, 0, 0.6)',
      boxShadowFocus: '0 0 0 4px rgba(210, 25, 60, .1)',
      borderRadius: '10px',
      paddingHorizontal: '16px',
    },
  },
  breakpoints: {
    desktop: 1200,
    medium: 1000,
    tablet: 750,
    embed: 500,
  },
  containerSizes: {
    tiny: '420px',
    tight: '30rem',
    narrow: '45rem',
    medium: '780px',
    default: '75rem',
    full: '100%',
  },
  fonts: {
    title:
      '"Raleway", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
    body: '"Inter", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
    system:
      '-apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
  },
  fontStacks: {
    d1: {
      sizeMinRem: 6,
      sizeMaxRem: 6,
      lineHeightMin: 1.25,
      lineHeightMax: 1.2,
      fontWeight: 700,
      defaultTag: 'h1',
      fontFamily: 'title',
    },
    d2: {
      sizeMinRem: 3.75,
      sizeMaxRem: 3.75,
      lineHeightMin: 1.25,
      lineHeightMax: 1.2,
      fontWeight: 900,
      defaultTag: 'h1',
      fontFamily: 'title',
    },
    d3: {
      sizeMinRem: 3,
      sizeMaxRem: 3,
      lineHeightMin: 1.25,
      lineHeightMax: 1.2,
      fontWeight: 900,
      defaultTag: 'h1',
    },
    h1: {
      sizeMinRem: 2.125,
      sizeMaxRem: 2.125,
      lineHeightMin: 1.25,
      lineHeightMax: 1.2,
      fontWeight: 700,
      defaultTag: 'h1',
      color: 'var(--ui-text)',
    },
    h2: {
      sizeMinRem: 1.5,
      sizeMaxRem: 1.5,
      lineHeightMin: 1.25,
      lineHeightMax: 1.2,
      fontWeight: 700,
      defaultTag: 'h2',
      color: 'var(--ui-text)',
    },
    h3: {
      sizeMinRem: 1.25,
      sizeMaxRem: 1.25,
      lineHeightMin: 1.31,
      lineHeightMax: 1.31,
      fontWeight: 700,
      defaultTag: 'h3',
      fontFamily: 'body',
      color: 'var(--ui-text)',
    },
    subtitle: {
      sizeMinRem: 1,
      sizeMaxRem: 1.125,
      lineHeightMin: 1.5,
      lineHeightMax: 1.25,
      fontWeight: 'bold',
      fontFamily: 'body',
      color: 'var(--ui-text)',
    },
    body: {
      sizeMinRem: 0.875,
      sizeMaxRem: 1,
      lineHeightMin: 1.5,
      lineHeightMax: 1.25,
      fontWeight: 400,
      fontFamily: 'body',
      color: 'var(--ui-text)',
    },
    body2: {
      sizeMinRem: 1,
      sizeMaxRem: 1.275,
      lineHeightMin: 1.5,
      lineHeightMax: 1.25,
      fontWeight: 700,
      fontFamily: 'body',
      color: 'var(--ui-text)',
    },
    callout: {
      sizeMinRem: 0.6,
      sizeMaxRem: 0.75,
      lineHeightMin: 1.25,
      lineHeightMax: 1.25,
      fontWeight: 400,
      fontFamily: 'body',
      color: 'rgba(255, 255, 255, 0.6)',
    },
    footnote: {
      sizeMinRem: 0.625,
      sizeMaxRem: 0.75,
      lineHeightMin: 1.25,
      lineHeightMax: 1.25,
      fontWeight: 400,
      fontFamily: 'body',
    },
  },
};
