import { Stack, Text } from '@tymate/margaret';
import styled from 'styled-components';
import { Avatar, PlayButton } from 'components';
import { formatDate, formatDuration } from 'utils';
import { MdCheckCircleOutline } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import { capitalize } from 'lodash';
import { useLocale, usePlayer } from 'hooks';
import { IcTarget } from './Icons';

const Card = styled(Stack).attrs({
  size: 'full',
  padding: 0.5,
  alignX: 'space-between',
})`
  background-color: ${({ theme }) => theme.backgroundTeamCard};
  border: 1px solid ${({ theme }) => theme.backgroundTeamCard};
  border-radius: 12px;
`;

const ActualityItem = ({ actuality }) => {
  const { t } = useTranslation('misc');
  const { setTrack, setCurrentAudio, currentAudio, togglePlayPause } =
    usePlayer();
  const { locale } = useLocale();

  const data = actuality?.node;
  const targeted = data?.podcast?.targeted;

  const audioChanged = currentAudio !== data?.id;

  const audioInfo = {
    podcastTitle: data?.podcast?.title,
    title: data?.title,
    audioSrc: data?.hlsUrl ?? '',
    image: data?.cover?.url ?? data?.podcast?.image?.url,
    duration: data?.duration,
  };

  const handlePlayButton = () => {
    setTrack(audioInfo);
    setCurrentAudio(data?.id);
    togglePlayPause();
  };

  return (
    <Stack direction="column">
      <Card>
        <Stack direction="column" gutterSize={1}>
          <Stack gutterSize={0.5} alignY="center">
            <Avatar
              imageUrl={data?.author?.avatar?.url}
              firstName={data?.author?.firstName}
              lastName={data?.author?.lastName}
              size="small"
            />
            <Text color="primary">
              {data?.author?.firstName} {data?.author?.lastName}
            </Text>
          </Stack>
          <Text marginTop={0.5}>{data?.title}</Text>
          <Stack gutterSize={1}>{targeted && <IcTarget />}</Stack>
        </Stack>

        <Stack>
          <Stack direction="column" gutterSize={0.5} alignX="flex-end">
            {data?.listening?.listened ? (
              <Stack alignY="center" gutterSize={1}>
                <MdCheckCircleOutline color="#79EEEB" size={20} />
                <Text color="primary">{t('listened')}</Text>
              </Stack>
            ) : (
              <Text color="textCallout">
                {formatDuration(data?.duration, {
                  withSeconds: true,
                })}
              </Text>
            )}
            <PlayButton onClick={handlePlayButton} idChanged={audioChanged} />
          </Stack>
        </Stack>
      </Card>
      <Stack size="full" alignX="flex-end" marginTop={0.5}>
        <Text color="textLight">
          {capitalize(formatDate(data?.createdAt, locale))}
        </Text>
      </Stack>
    </Stack>
  );
};

export default ActualityItem;
