import { OrganizationHeader, PodcastsSection } from 'components';
import { useApp } from 'hooks';
import Organization from './Organization';
import { Main, Wrapper } from 'ui';
import { useQuery } from '@apollo/client';
import { GET_CATEGORY_PODCASTS } from 'api';
import { useTranslation } from 'react-i18next';
import { Spinner } from '@tymate/margaret';

const Home = () => {
  const { organization } = useApp();
  const { t } = useTranslation('home');

  const { data: internalProductionData, loading: loadingInternalProduction } =
    useQuery(GET_CATEGORY_PODCASTS, {
      variables: {
        slug: 'internal_production',
        first: 4,
      },
    });

  const { data: audioLearningData, loading: loadingAudioLearning } = useQuery(
    GET_CATEGORY_PODCASTS,
    {
      variables: {
        slug: 'audio_learning',
        first: 4,
      },
    },
  );

  return (
    <>
      <OrganizationHeader
        backgroundImage={organization?.banner?.url}
        organization={organization}
        withText
      />

      <Main>
        <Wrapper>
          {loadingInternalProduction &&
          loadingAudioLearning &&
          !Boolean(organization) ? (
            <Spinner />
          ) : (
            <>
              <Organization />

              <PodcastsSection
                category={internalProductionData?.category}
                title={t('internalProduction.title')}
              />

              <PodcastsSection
                category={audioLearningData?.category}
                title={t('audioLearning.title')}
                isChapter
              />
            </>
          )}
        </Wrapper>
      </Main>
    </>
  );
};

export default Home;
