import { forgotPassword } from 'api/auth';
import { Stack, Text } from '@tymate/margaret';
import { Button, Layout } from 'components';
import { useTranslation } from 'react-i18next';
import { TextField } from 'components/Fields';
import { useAuth } from 'hooks';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Navigate } from 'react-router-dom';
import { useState } from 'react';
import { ErrorMessage } from 'ui/forms';
import { formatRestError } from 'utils';

const ForgetPassword = () => {
  const { t } = useTranslation(['passwordForgotten', 'errors']);
  const { onUpdate, accessToken } = useAuth();
  const [steps, setSteps] = useState(1);

  const handleForgetPassword = async (values, { setStatus }) => {
    try {
      const { data } = await forgotPassword(values);
      onUpdate(data);
      setSteps(2);
    } catch (err) {
      setStatus(t(`errors:${formatRestError(err)}`));
    }
  };

  if (Boolean(accessToken)) {
    return <Navigate to="/" />;
  }

  const StepForgetPassword = () => (
    <>
      <div>
        <Text type="h1">{t('passwordForgotten')}</Text>
      </div>
      <div>
        <Text
          type="body"
          fontWeight="bold"
          style={{
            textAlign: 'center',
          }}
        >
          {t('passwordForgottenHint')}
        </Text>
      </div>
      <Formik
        initialValues={{ email: '' }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email(t('errors:email'))
            .required(t('errors:required')),
        })}
        onSubmit={handleForgetPassword}
      >
        {({ isSubmitting, status }) => (
          <Form style={{ width: '100%' }}>
            <Stack direction="column" alignX="center" gap={2} size="full">
              <TextField
                label={t('passwordForgotten:email')}
                id="login-username"
                autoComplete="email"
                placeholder=""
                name="email"
                type="email"
              />
              {Boolean(status) && <ErrorMessage>{status}</ErrorMessage>}

              <Button variant="primary" size="full" isLoading={isSubmitting}>
                {t('button:passwordForgotten')}
              </Button>
              <Button type="button" variant="outline" to="/login">
                {t('button:back')}
              </Button>
            </Stack>
          </Form>
        )}
      </Formik>
    </>
  );

  return (
    <Layout size="tiny">
      <Stack alignX="center" direction="column" gutterSize={2} size="full">
        {steps === 1 && <StepForgetPassword />}
        {steps === 2 && (
          <div>
            <Text type="h1">{t('passwordForgottenSuccess')}</Text>
          </div>
        )}
      </Stack>
    </Layout>
  );
};

export default ForgetPassword;
