import { gql } from '@apollo/client';

export const GET_CATEGORY = gql`
  query category($slug: String!) {
    category(slug: $slug) {
      id
      slug
      title
    }
  }
`;

export const GET_CATEGORY_AUDIOS = gql`
  query categoryAudios($slug: String!, $tagIds: [ID!], $first: Int) {
    category(slug: $slug) {
      id
      title
      audios(
        first: $first
        tagIds: $tagIds
        order: { column: PUBLISHED_AT, direction: DESC }
      ) {
        edges {
          node {
            id
            description
            duration
            title
            hlsUrl
            createdAt
            author {
              ... on User {
                id
                firstName
                lastName
                discardedAt
                avatar(size: medium_section) {
                  id
                  url
                }
              }
              ... on External {
                id
                firstName
                lastName
                avatar(size: medium_section) {
                  id
                  url
                }
              }
            }
            podcast {
              id
              longDescription
              title
              targeted
              category {
                id
                slug
              }
              image(size: medium_section) {
                id
                url
              }
            }
            listening {
              id
              listened
            }
            contributors {
              edges {
                node {
                  id
                  contributorable {
                    ... on User {
                      firstName
                      lastName
                      discardedAt
                    }
                    ... on External {
                      firstName
                      lastName
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_CATEGORY_PODCASTS = gql`
  query categoryPodcasts($slug: String!, $tagIds: [ID!], $first: Int) {
    category(slug: $slug) {
      id
      slug
      podcasts(
        tagIds: $tagIds
        first: $first
        order: { column: NEWEST_CONTENT, direction: DESC }
      ) {
        edges {
          node {
            id
            kind
            title
            longDescription
            createdAt
            targeted
            image(size: medium_section) {
              id
              url
            }
            aggregateDurationTime
            audiosCount
            audios(order: { column: CREATED_AT, direction: DESC }) {
              edges {
                node {
                  id
                  description
                  duration
                  hlsUrl
                  podcast {
                    id
                    longDescription
                    title
                    category {
                      id
                      slug
                    }
                  }
                  listening {
                    id
                  }
                  contributors {
                    edges {
                      node {
                        id
                        contributorable {
                          ... on User {
                            firstName
                            lastName
                          }
                          ... on External {
                            firstName
                            lastName
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_ACTUALITIES_PODCASTS = gql`
  query categoryPodcasts($slug: String!, $first: Int) {
    category(slug: $slug) {
      id
      homePodcasts(first: $first) {
        edges {
          node {
            id
            title
            image(size: medium_section) {
              id
              url
            }
          }
        }
      }
    }
  }
`;

export const GET_ADMIN_CATEGORY_PODCASTS = gql`
  query categoryPodcasts($slug: String!, $tagIds: [ID!], $first: Int) {
    category(slug: $slug) {
      id
      slug
      podcasts(
        tagIds: $tagIds
        first: $first
        order: { column: NEWEST_CONTENT, direction: DESC }
        appContext: "bo"
      ) {
        edges {
          node {
            id
            kind
            title
            targeted
            image(size: medium_section) {
              id
              url
            }
          }
        }
      }
    }
  }
`;

export const getCategoryTitleKey = category => {
  switch (category) {
    case 'personal_development':
      return 'personalDevelopment.title';
    case 'actualities':
      return 'actualities.title';
    case 'welcome_desk':
      return 'welcomeDesk.title';
    case 'masterclass':
      return 'masterclasses.title';
    case 'well_being':
      return 'wellBeing.title';
    case 'audio_learning':
      return 'audioLearning.title';
    case 'internal_production':
      return 'internalProduction.title';
    case 'discovery':
      return 'discovery.title';
    default:
      return 'misc.missing';
  }
};
