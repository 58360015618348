import styled, { css } from 'styled-components';
import { ButtonReset, Stack, Box } from '@tymate/margaret';

export const Input = styled.input`
  padding: ${({ theme }) => theme.spacing(0.25)}
    ${({ theme }) => theme.spacing(0.5)};
  border: 1px solid ${({ theme }) => theme.separator};
  flex: 1;
  border-radius: 8px;
  transition: border-color 100ms ease;
  min-height: 40px;
  min-width: 327px;
  width: 100%;
  color: #fff;
  background-color: ${({ theme }) => theme.background};

  ::placeholder {
    color: #ffffff;
  }

  &:not([disabled]):focus,
  &:not([disabled]):active,
  &:not([disabled]):hover {
    outline: none;
    border: 1px solid ${({ theme }) => theme.inputFocusBorder};
    ${({ theme }) => theme.inputFocusBoxShadow}
  }

  ${({ type }) =>
    type === 'password' &&
    css`
      padding-right: 44px;
    `}

  ${({ light }) =>
    light &&
    css`
      color:${({ theme }) => theme.textDark}; ;
      background-color: #fff};
      border: 1px solid rgba(230, 238, 240, 1);

      ::placeholder {
        color: rgba(169, 191, 195, 1);
      }

      &:not([disabled]):focus,
      &:not([disabled]):active,
      &:not([disabled]):hover {
        outline: none;
        border: 1px solid rgba(230, 238, 240, 1);
      }
   `}

  &:disabled {
    cursor: not-allowed;
    background-color: ${({ theme }) => theme.inputDisabledBackground};
    color: ${({ theme }) => theme.inputDisabledColor};
    border: 1px solid ${({ theme }) => theme.inputDisabledBorder};
  }

  ${({ hasError, theme }) =>
    hasError &&
    css`
      &:not([disabled]),
      &:not([disabled]):hover,
      &:not([disabled]):active {
        border: 1px solid ${theme.error};
      }
    `}
`;

export const FormGroup = styled.div`
  > * {
    margin-bottom: ${({ theme }) => theme.spacing(1.5)};
  }
`;

export const FormLabel = styled(Box).attrs({ as: 'label', marginBottom: 0.5 })`
  ${({ theme }) => theme.fontStyles.bodySmall};
  margin-bottom: ${({ theme }) => theme.spacing(0.5)};
  font-weight: 600;
  color: ${({ theme }) => theme.text};

  ${({ disabled }) =>
    Boolean(disabled) &&
    css`
      color: ${({ theme }) => theme.inputDisabledColor};
    `}

  ${({ variant }) =>
    variant === 'bare' &&
    `
      margin-bottom: 0;
    `}
`;

export const FormField = styled(Stack).attrs({
  direction: 'column',
  size: 'full',
  alignX: 'stretch',
})`
  position: relative;
`;

export const PasswordButton = styled(ButtonReset)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: ${({ theme }) => theme.spacing(0.75)};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.textLight};
`;

export const ErrorMessage = styled.div`
  color: ${({ theme }) => theme.error};
  max-width: 327px;
  ${({ theme }) => theme.fontStyles.callout};
  padding-top: ${({ theme }) => theme.spacing()};
  padding-bottom: ${({ theme }) => theme.spacing()};
`;

export const ErrorMessageWrapper = styled.div`
  ${({ theme }) => theme.fontStyles.callout};
  color: ${({ theme }) => theme.error};
  padding-top: ${({ theme }) => theme.spacing(0.5)};

  position: absolute;
  top: 100%;
`;
