import React from 'react';

const CheckCircle = ({ size = 12, ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6-.001a6 6 0 100 12 6 6 0 000-12zM2.288 2.287A5.25 5.25 0 119.712 9.71a5.25 5.25 0 01-7.424-7.424zm7.006 1.36a.375.375 0 10-.588-.466L4.784 8.143l-.004.006a.15.15 0 01-.243-.008l-.005-.007-1.225-1.738a.375.375 0 00-.614.432l1.223 1.735a.9.9 0 001.459.043l3.92-4.96z"
        fill="var(--colors-cyan-300)"
      />
    </svg>
  );
};

export default CheckCircle;
