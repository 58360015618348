import { useEffect, useState } from 'react';
import AppBanner from 'components/AppBanner';
import { isMobile } from 'react-device-detect';
import { AppBannerContext } from 'hooks';

const AppBannerProvider = ({ children }) => {
  const [appBannerIsShown, setAppBannerIsShown] = useState(false);

  useEffect(() => {
    setAppBannerIsShown(
      isMobile && !localStorage.getItem('hasDismissedBanner'),
    );
  }, []);

  const handleDismissAppBanner = () => {
    setAppBannerIsShown(false);
  };

  return (
    <AppBannerContext.Provider
      value={{
        handleDismissAppBanner,
      }}
    >
      {appBannerIsShown && <AppBanner onDismiss={handleDismissAppBanner} />}
      {children}
    </AppBannerContext.Provider>
  );
};

export default AppBannerProvider;
