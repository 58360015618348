import { PlayerContext } from 'hooks';
import { useState, useRef } from 'react';
import { useDeepCompareEffect, useInterval } from 'react-use';
import { useMutation, useQuery } from '@apollo/client';
import { GET_USER_LISTENINGS, UPDATE_LISTENING } from 'api/audio';

const PlayerProvider = ({ children }) => {
  const [track, setTrack] = useState(false);
  const [currentAudio, setCurrentAudio] = useState(0);
  const audioRef = useRef();
  const intervalRef = useRef();
  const isReady = useRef(false);

  const [isPlaying, setIsPlaying] = useState(false);
  const [trackProgress, setTrackProgress] = useState(0);
  const [volume, setVolume] = useState(100);

  const duration = audioRef?.current?.duration;

  const startTimer = () => {
    // Clear any timers already running
    clearInterval(intervalRef.current);

    intervalRef.current = setInterval(() => {
      if (audioRef?.current?.ended) {
        setTrackProgress(0);
        setTrack(false);
        setCurrentAudio(0);
      } else {
        setTrackProgress(audioRef.current.currentTime);
      }
    }, [1000]);
  };

  const playAudio = () => {
    if (audioRef.current) {
      clearInterval(intervalRef.current);
      audioRef.current.pause();
    }

    audioRef.current = new Audio(track.audioSrc);
    audioRef.current.playsInline = true;
    audioRef.current.preload = 'metadata';
    audioRef.current.title = track?.title;
    audioRef.current.currentTime = track.listeningDuration || 0;
    audioRef.current.onended = () => {
      handleUpdateListening();
    };

    setTrackProgress(audioRef.current.currentTime);
    isReady.current = true;
    audioRef.current.play();
    startTimer();
    setIsPlaying(true);
  };

  const handlePlayPause = () => {
    if (Boolean(track && currentAudio)) {
      if (!isPlaying) {
        audioRef.current.play();
        startTimer();
        setIsPlaying(true);
      } else {
        audioRef.current.pause();
        setIsPlaying(false);
      }
    }
  };

  const handleScrub = value => {
    // Clear any timers already running
    clearInterval(intervalRef.current);
    audioRef.current.currentTime = value;
    setTrackProgress(audioRef.current.currentTime);
  };

  const handleScrubEnd = () => {
    // If not already playing, start
    if (!isPlaying) {
      setIsPlaying(true);
      audioRef.current.play();
      startTimer();
    }
    startTimer();
  };

  const handleForward = () => {
    clearInterval(intervalRef.current);
    const newCurrentTime = Math.min(
      audioRef.current.currentTime + 30,
      duration,
    );
    audioRef.current.currentTime = newCurrentTime;
    setTrackProgress(newCurrentTime);
    handleScrubEnd();
  };

  const handleRewind = () => {
    clearInterval(intervalRef.current);
    const newCurrentTime = Math.max(audioRef.current.currentTime - 15, 0);
    audioRef.current.currentTime = newCurrentTime;
    setTrackProgress(newCurrentTime);
    handleScrubEnd();
  };

  const handleVolume = value => {
    audioRef.current.volume = value / 100;
    setVolume(value);
  };

  useDeepCompareEffect(() => {
    if (!Boolean(track) || !Boolean(currentAudio)) {
      return;
    }
    playAudio();
  }, [{ track }]);

  // UPDATE LISTENING

  const { refetch } = useQuery(GET_USER_LISTENINGS);
  const [updateListening] = useMutation(UPDATE_LISTENING);

  const updateListeningDelay = isPlaying ? 5000 : null;

  useInterval(() => {
    handleUpdateListening();
  }, updateListeningDelay);

  const handleUpdateListening = async ({ shouldRefetchListenings } = {}) => {
    if (currentAudio) {
      await updateListening({
        variables: {
          input: {
            audibleId: currentAudio,
            duration: Math.round(audioRef.current.currentTime || 0),
          },
        },
      });

      if (Boolean(shouldRefetchListenings)) {
        await refetch();
      }
    }
  };

  return (
    <PlayerContext.Provider
      value={{
        track,
        setTrack,
        currentAudio,
        setCurrentAudio,
        togglePlayPause: handlePlayPause,
        scrub: handleScrub,
        scrubEnd: handleScrubEnd,
        skipForward: handleForward,
        skipBackward: handleRewind,
        isPlaying,
        trackProgress,
        volume,
        handleVolume,
        duration,
      }}
    >
      {children}
    </PlayerContext.Provider>
  );
};

export default PlayerProvider;
