import { usePlayer } from 'hooks';
import { ButtonReset } from '@tymate/margaret';
import { IcPlay, IcPause, IcReplay } from 'components/Icons';

const PlayButton = ({ onClick, idChanged, size = 48, replay }) => {
  const { isPlaying } = usePlayer();

  return (
    <ButtonReset onClick={onClick}>
      {isPlaying && !idChanged ? (
        <IcPause
          size={size}
          colorCircleFill="rgba(0, 170, 255, 0.16)"
          colorFill="var(--ui-primary)"
        />
      ) : replay ? (
        <IcReplay
          size={size}
          colorCircleFill="rgba(0, 170, 255, 0.16)"
          colorFill="var(--ui-primary)"
        />
      ) : (
        <IcPlay
          size={size}
          colorCircleFill="rgba(0, 170, 255, 0.16)"
          colorFill="var(--ui-primary)"
        />
      )}
    </ButtonReset>
  );
};

export default PlayButton;
