import styled, { css } from 'styled-components';
import { Stack, Text } from '@tymate/margaret';
import { formatDuration } from 'utils';
import { MdCheckCircleOutline } from 'react-icons/md';
import { useTranslation } from 'react-i18next';

const WrapperAudioCard = styled(Stack).attrs({
  size: 'full',
})``;

const WrapperImage = styled.img`
  width: 96px;
  height: 96px;
  border-radius: 12px;
  object-fit: cover;
`;

const Title = styled(Text).attrs({ type: 'body' })`
  ${({ isActive }) =>
    isActive &&
    css`
      color: ${({ theme }) => theme.primary};
    `}
`;

const AudioCard = ({ isActive, audio, imagePodcast }) => {
  const { t } = useTranslation('podcast');

  return (
    <WrapperAudioCard gutterSize={1}>
      <WrapperImage src={imagePodcast} />
      <Stack direction="column">
        <Title isActive={isActive}>{audio?.title}</Title>

        <Text type="body" style={{ color: 'rgba(255, 255, 255, 0.38)' }}>
          {formatDuration(audio?.duration, 'withSeconds')}
        </Text>

        {audio?.listening?.listened && (
          <Stack
            alignX="flex-start"
            alignY="center"
            style={{ color: 'var(--ui-primary)' }}
          >
            <MdCheckCircleOutline color="var(--ui-primary)" /> {t('finish')}
          </Stack>
        )}
      </Stack>
    </WrapperAudioCard>
  );
};
export default AudioCard;
