const PlayBack = props => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ ...props.style, fontSize: props.size }}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.18 13.489L.22 10.527a.75.75 0 011.06-1.06l1.768 1.768A10.494 10.494 0 0113.5 1.75c5.794 0 10.5 4.695 10.5 10.5 0 5.794-4.706 10.5-10.5 10.5a.75.75 0 010-1.5 9 9 0 100-18 8.994 8.994 0 00-8.93 7.866l1.65-1.65a.75.75 0 111.06 1.061L4.32 13.49a.748.748 0 01-1.14 0z"
        fill={props.colorFill ?? '#000'}
        fillOpacity={props.fillOpacity ?? 0.38}
      />
      <path
        d="M11.62 14.76a.684.684 0 101.367 0V9.684A.684.684 0 0012.303 9h-.018c-.189 0-.387.045-.648.117l-.89.261a.614.614 0 00-.46.594c0 .324.28.594.603.594.072 0 .171-.018.243-.036l.486-.126v4.356zM16.104 15.498c1.386 0 2.349-.846 2.349-2.178v-.018c0-1.422-.963-2.016-2.205-2.016-.351 0-.576.045-.828.108l.072-1.089h2.124a.61.61 0 00.612-.603.619.619 0 00-.612-.612H14.88a.615.615 0 00-.612.594l-.126 2.133a.588.588 0 00.252.558c.279.198.432.279.63.279.189 0 .423-.162.954-.162.657 0 1.116.315 1.116.855v.018c0 .558-.432.873-1.017.873-.441 0-.81-.126-1.197-.405a.58.58 0 00-.378-.126.668.668 0 00-.657.666c0 .234.117.414.279.531.504.36 1.107.594 1.98.594z"
        fill={props.colorFill ?? '#000'}
        fillOpacity={props.fillOpacity ?? 0.38}
      />
    </svg>
  );
};

export default PlayBack;
