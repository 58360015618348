import { useState, useRef } from 'react';
import { useField } from 'formik';
import { Stack, Dropdown } from '@tymate/margaret';
import { FormField, FormLabel, Input, ErrorMessageWrapper } from 'ui/forms';
import { PopoverMenu, PopoverItem, FlexTruncatedText } from 'ui';
import { find, uniqueId } from 'lodash';
import { MdKeyboardArrowDown } from 'react-icons/md';
import Scrollbars from 'react-custom-scrollbars';

const SelectField = ({
  label,
  labelSuffix,
  options,
  placeholder,
  disabled,
  ...props
}) => {
  const [field, meta, { setValue }] = useField(props);
  const [isOpen, setIsOpen] = useState();
  const [id] = useState(uniqueId());

  const dropdownRef = useRef();
  const hasError = Boolean(meta.touched) && Boolean(meta.error);

  const handleSelect = value => {
    setValue(value);
    dropdownRef.current.close();
  };

  return (
    <FormField>
      {(Boolean(label) || Boolean(labelSuffix)) && (
        <Stack alignX="space-between" alignY="center" marginBottom={0.5}>
          {label && (
            <FormLabel
              variant="bare"
              htmlFor={id}
              onClick={() => dropdownRef.current.open()}
            >
              {label}
            </FormLabel>
          )}
          {labelSuffix && labelSuffix}
        </Stack>
      )}

      <Stack
        style={{ position: 'relative' }}
        size="full"
        alignX="stretch"
        direction="column"
      >
        <Dropdown
          ref={dropdownRef}
          onToggle={setIsOpen}
          trigger={
            <Input
              as={Stack}
              size="full"
              variant={isOpen ? 'active' : null}
              isDisabled={disabled}
              disabled={disabled}
              inputVariant={props.variant}
              hasError={hasError}
            >
              <Stack
                size="full"
                alignX="space-between"
                gutterSize={1}
                alignY="center"
              >
                <FlexTruncatedText isPlaceholder={field.value ? false : true}>
                  {find(options, { value: field.value })?.label ||
                    placeholder ||
                    ''}
                </FlexTruncatedText>
                <MdKeyboardArrowDown size={24} color="var(--ui-text-light)" />
              </Stack>
            </Input>
          }
          disabled={disabled}
          {...props}
        >
          <PopoverMenu>
            <Scrollbars autoHeight>
              {options.map(({ label, value }, index) => (
                <PopoverItem
                  size="full"
                  key={index}
                  type="button"
                  onClick={() => handleSelect(value)}
                  isActive={field.value === value}
                  tabIndex={-1}
                >
                  <FlexTruncatedText>{label}</FlexTruncatedText>
                </PopoverItem>
              ))}
            </Scrollbars>
          </PopoverMenu>
        </Dropdown>

        {meta.touched && meta.error ? (
          <ErrorMessageWrapper>{meta.error}</ErrorMessageWrapper>
        ) : null}
      </Stack>
    </FormField>
  );
};

export default SelectField;
