import { Main, Tag, Wrapper, TargetsTag } from 'ui';
import { Spinner, Stack, Text } from '@tymate/margaret';
import { GET_PODCAST } from 'api';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PodcastHeader from 'components/PodcastHeader';
import { union, uniqBy } from 'lodash';
import ProfileCard from 'components/ProfilCard';
import { Button } from 'components';
import { useState } from 'react';
import ChapterItem from 'components/ChapterItem';
import AudioItem from 'components/AudioItem';
import { IcTarget } from 'components/Icons';

const PodcastDetail = () => {
  const { t } = useTranslation('podcastDetail');
  const { id } = useParams();

  const [showMore, setShowMore] = useState();

  const { data, loading } = useQuery(GET_PODCAST, {
    variables: { podcastId: id },
  });

  const podcast = data?.podcast;
  const podcastCategory = podcast?.category?.slug;
  const isChapterPodcast =
    podcastCategory === 'audio_learning' || podcastCategory === 'masterclass';

  const authors = uniqBy(
    podcast?.audios?.edges?.map(({ node }) => node?.author) || [],
    'id',
  ).filter(Boolean);
  const tags = podcast?.tags?.edges || [];

  const contributors = (
    union(
      podcast?.audios?.edges
        ?.map(({ node }) =>
          node?.contributors?.edges?.map(({ node }) => node?.contributorable),
        )
        ?.flat(),
    ) || []
  ).filter(Boolean);

  const showContributors = contributors.length > 0 && isChapterPodcast;

  const showMoreButton =
    showContributors ||
    tags?.length > 0 ||
    podcast?.longDescription?.length > 150 ||
    podcast?.targeted;

  const audiosEdges = podcast?.audios?.edges || [];

  const areas = podcast?.areas?.edges ?? [];
  const departments = podcast?.departments?.edges ?? [];
  const services = podcast?.services?.edges ?? [];

  const targets = [...areas, ...departments, ...services];

  return (
    <>
      <PodcastHeader image={podcast?.image?.url} />

      <Main>
        <Wrapper>
          {loading ? (
            <Spinner />
          ) : (
            <Stack size="full" direction="column">
              <Text type="h1" flexGrow={1} style={{ textAlign: 'center' }}>
                {podcast?.title}
              </Text>
              {authors.length > 0 && (
                <div style={{ paddingTop: 50, paddingBottom: 18 }}>
                  {authors?.map(({ lastName, firstName, avatar, id }) => {
                    return (
                      <div
                        key={id}
                        style={{
                          display: 'inline-block',
                          marginRight: 10,
                        }}
                      >
                        <ProfileCard
                          lastName={lastName}
                          firstName={firstName}
                          avatar={avatar?.url}
                        />
                      </div>
                    );
                  })}
                </div>
              )}

              {showMore ? (
                <Text color="textLight" marginBottom={2}>
                  {Boolean(podcast?.longDescription) &&
                    podcast?.longDescription}
                </Text>
              ) : (
                <Stack direction="column" gap={0.5}>
                  <Text color="textLight">
                    {Boolean(podcast?.longDescription) &&
                      `${podcast?.longDescription?.substring(0, 150)}...`}
                  </Text>
                  {podcast?.targeted && <IcTarget white />}
                </Stack>
              )}

              {showMore && tags.length > 0 && (
                <Stack direction="column" marginBottom={2}>
                  <Text type="body" fontWeight={700}>
                    {t('tags')}
                  </Text>
                  <Stack gap={1}>
                    {(tags ?? []).map(({ node }) => (
                      <Tag key={node?.id}>{node?.name}</Tag>
                    ))}
                  </Stack>
                </Stack>
              )}
              {showMore && showContributors && (
                <Stack direction="column" marginBottom={2.5}>
                  <Text type="body" fontWeight={700}>
                    {t('contributors')}
                  </Text>
                  {contributors.map(({ lastName, firstName, avatar, id }) => (
                    <div
                      key={id}
                      style={{
                        marginTop: 10,
                      }}
                    >
                      <ProfileCard
                        lastName={lastName}
                        firstName={firstName}
                        avatar={avatar?.url}
                      />
                    </div>
                  ))}
                </Stack>
              )}

              {showMore && podcast?.targeted && (
                <Stack direction="column" gap={1} marginBottom={1}>
                  <Stack gap={0.5}>
                    <IcTarget white />
                    <Text type="body" fontWeight={700}>
                      {t('accessibleTo')}
                    </Text>
                  </Stack>
                  <Stack gap={0.5}>
                    {(targets ?? []).map(({ node }) => (
                      <TargetsTag key={node?.id}>
                        {node?.title ?? node?.displayName}
                      </TargetsTag>
                    ))}
                  </Stack>
                </Stack>
              )}

              {showMoreButton && (
                <Stack size="full" alignX="flex-end">
                  <Button onClick={() => setShowMore(!showMore)}>
                    <Text color="primary">
                      {showMore ? t('showLess') : t('showMore')}
                    </Text>
                  </Button>
                </Stack>
              )}
              <Stack direction="column" marginTop={1}>
                {audiosEdges.map(({ node }, index) =>
                  isChapterPodcast ? (
                    <ChapterItem
                      key={index}
                      audio={node}
                      onPress={() => {}}
                      isTheLastAudio={audiosEdges?.length === index + 1}
                    />
                  ) : (
                    <AudioItem key={index} audio={node} />
                  ),
                )}
              </Stack>
            </Stack>
          )}
        </Wrapper>
      </Main>
    </>
  );
};

export default PodcastDetail;
