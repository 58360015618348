import { Main, Wrapper } from 'ui';
import { CategoryCard, OrganizationHeader } from 'components';
import { useApp } from 'hooks';
import { Spinner, Stack, Text } from '@tymate/margaret';
import { getCategoryTitleKey, GET_CATEGORY_PODCASTS } from 'api';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { formatDuration } from 'utils';
import BackButton from 'components/BackButton';
import { IcTarget } from 'components/Icons';

const PodcastsWrapper = styled(Stack).attrs({
  size: 'full',
  paddingTop: 1,
  wrap: 'wrap',
})`
  display: grid;
  column-gap: 20px;
  row-gap: 20px;
  grid-template-columns: repeat(2, 1fr);

  ${({ theme }) => theme.media.tablet`
    grid-template-columns: repeat(4, 1fr);
  `};

  ${({ theme }) => theme.media.desktop`
    grid-template-columns: repeat(4, 1fr);
  `};
`;

const Category = () => {
  const { organization } = useApp();
  const { t } = useTranslation('category');
  const { slug } = useParams();

  const { data: categoryData, loading } = useQuery(GET_CATEGORY_PODCASTS, {
    variables: {
      slug: slug,
    },
  });

  const podcastCategory = categoryData?.category?.podcasts?.edges || [];

  const isChapter = Boolean(slug === 'audio_learning');

  return (
    <>
      <OrganizationHeader
        backgroundImage={organization?.banner?.url}
        organization={organization}
        withText
      />

      <Main>
        <Wrapper>
          <Stack size="full" direction="column">
            <Stack>
              <BackButton />
            </Stack>
            <Text type="h1">{t(getCategoryTitleKey(slug))}</Text>
            {loading ? (
              <Spinner />
            ) : (
              <PodcastsWrapper>
                {podcastCategory.map(({ node }) => (
                  <Stack size="full" direction="column" key={node?.id}>
                    <CategoryCard
                      backgroundImage={node?.image?.url}
                      withoutGradient
                      to={`/podcast/${node?.id}`}
                    />
                    <Stack alignY="center" gap={1} paddingVertical={0.5}>
                      <Text color="textCallout" type="callout">
                        {isChapter
                          ? t('chapters', {
                              count: node?.audiosCount,
                            })
                          : t('episodes', {
                              count: node?.audiosCount,
                            })}
                        {' • '}
                        {formatDuration(node?.aggregateDurationTime, {
                          format: 'chapters',
                        })}
                      </Text>
                      {node?.targeted && <IcTarget />}
                    </Stack>
                    <Text fontWeight={700}>{node?.title}</Text>
                  </Stack>
                ))}
              </PodcastsWrapper>
            )}
          </Stack>
        </Wrapper>
      </Main>
    </>
  );
};

export default Category;
