import { Stack, Text } from '@tymate/margaret';
import { useTranslation } from 'react-i18next';
import { Header } from 'components';
import { Banner, BackgroundImage } from 'ui';

const OrganizationHeader = ({ backgroundImage, withText, organization }) => {
  const { t } = useTranslation('home');

  return (
    <BackgroundImage backgroundImage={backgroundImage}>
      <Header homeHeader>
        <Banner backgroundImage={backgroundImage}>
          {withText && (
            <Stack
              size="full"
              alignX="center"
              alignY="flex-end"
              direction="column"
              style={{ height: '100%' }}
              paddingBottom={2}
            >
              <Text type="d3" color="text" paddingHorizontal={1}>
                {organization?.displayName}
              </Text>
              <Text color="textLight">{t('audiosOfOrganization')}</Text>
            </Stack>
          )}
        </Banner>
      </Header>
    </BackgroundImage>
  );
};

export default OrganizationHeader;
