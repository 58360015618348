import { settingPassword } from 'api/auth';
import { Stack, Text } from '@tymate/margaret';
import { Navigate, useNavigate } from 'react-router-dom';
import { Button, CheckboxField } from 'components';
import { useTranslation } from 'react-i18next';
import { TextField } from 'components/Fields';
import { useAuth } from 'hooks';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Layout from 'components/Layout';
import { useSearchParams } from 'utils';

const CreatePassword = () => {
  const [{ token }] = useSearchParams();
  const navigate = useNavigate();
  const { onUpdate, accessToken } = useAuth();
  const { t } = useTranslation([
    'accountActivation',
    'button',
    'errors',
    'login',
  ]);

  const handleSettingPassword = async values => {
    try {
      const { data } = await settingPassword({
        accessToken: token,
        ...values,
      });
      onUpdate(data);
      navigate('/profile');
    } catch (err) {
      console.log({ err });
    }
  };

  if (Boolean(accessToken)) {
    return <Navigate to="/" />;
  }

  return (
    <Layout size="tiny">
      <Formik
        initialValues={{ password: '', cguAccepted: false }}
        validationSchema={Yup.object().shape({
          password: Yup.string().required(t('errors:required')).min(8),
          cguAccepted: Yup.bool().required(t('errors:required')),
        })}
        onSubmit={handleSettingPassword}
      >
        {({ values, isSubmitting }) => (
          <Form style={{ width: '100%' }}>
            <Stack direction="column" alignX="center" gap={2} size="full">
              <div>
                <Text type="h1">{t('passwordCreation:passwordCreation')}</Text>
              </div>
              <div>
                <Text type="h2">{values.email}</Text>
              </div>
              <div>
                <Text
                  style={{
                    textAlign: 'center',
                  }}
                  type="body"
                >
                  {t('passwordCreation:passwordCreationHint')}
                </Text>
              </div>
              <Stack direction="column" alignX="center" gap={2}>
                <TextField
                  id="login-password"
                  type="password"
                  label={t('passwordCreation:password')}
                  name="password"
                  autoComplete="off"
                />
                <CheckboxField
                  name={'cguAccepted'}
                  label={t('passwordCreation:conditionAcception')}
                  lineLabel={t('passwordCreation:gtu')}
                  value={values?.cguAccepted}
                />
                <Button variant="primary" size="full" isLoading={isSubmitting}>
                  {t('button:activateAccount')}
                </Button>
              </Stack>
            </Stack>
          </Form>
        )}
      </Formik>
    </Layout>
  );
};

export default CreatePassword;
