import { Main, Wrapper } from 'ui';
import { OrganizationHeader } from 'components';
import { useApp } from 'hooks';

const WelcomeDesk = () => {
  const { organization } = useApp();
  return (
    <>
      <OrganizationHeader
        backgroundImage={organization?.banner?.url}
        organization={organization}
        withText
      />

      <Main>
        <Wrapper>{/* 👇🏼 ici détail welcomeDesk */}</Wrapper>
      </Main>
    </>
  );
};

export default WelcomeDesk;
