import { Stack, Text } from '@tymate/margaret';
import styled from 'styled-components';
import { Layout } from 'components';
import { useTranslation } from 'react-i18next';
import Lottie from 'react-lottie';
import animationData from 'animations/error.json';

const LottieWrapper = styled(Stack)`
  padding-top: ${({ theme }) => theme.spacing(5)};
`;

const NotFound = () => {
  const { t } = useTranslation('errors');

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <Layout>
      <Stack direction="column" alignX="center" gap={2}>
        <div>
          <Text type="h1">{t('podcastNotFound')}</Text>
        </div>
        <div>
          <Text type="body">{t('podcastNotFoundHint')}</Text>
        </div>
        <LottieWrapper alignY="flex-end">
          <Lottie options={defaultOptions} width={442} height={288} />
        </LottieWrapper>
      </Stack>
    </Layout>
  );
};

export default NotFound;
