import React from 'react';
import { useQuery } from '@apollo/client';
import { GET_LISTENING } from 'api';
import { useTranslation } from 'react-i18next';
import { Text } from '@tymate/margaret';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import CheckCircle from './Icons/CheckCircle';

const AudioItemProgress = ({ audio }) => {
  const { t } = useTranslation('podcastDetail');

  const { data } = useQuery(GET_LISTENING, {
    variables: { listeningId: audio?.listening?.id },
  });
  const listening = data?.listening;

  if (listening?.listened) {
    return (
      <Text color="primary" noOfLines={1}>
        <CheckCircle color="primary" /> {t('listened')}
      </Text>
    );
  }

  return (
    <Slider
      value={listening?.duration}
      step={1}
      min={0}
      max={audio?.duration ? audio?.duration : `${audio?.duration}`}
      trackStyle={{
        backgroundColor: 'var(--ui-primary)',
      }}
      handleStyle={{ display: 'none' }}
      railStyle={{ backgroundColor: 'var(--ui-separator)' }}
    />

  );
};

export default AudioItemProgress;
