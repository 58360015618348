const Replay = props => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ ...props.style, fontSize: props.size }}
      {...props}
    >
      <circle
        cx={24}
        cy={24}
        r={24}
        fill={props.colorCircleFill ?? '#79EEEB'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.548 13.952a10.499 10.499 0 0 0-12.499 9.283l-1.769-1.768a.75.75 0 1 0-1.06 1.06l2.961 2.962a.748.748 0 0 0 1.138 0l2.961-2.962a.75.75 0 1 0-1.06-1.06l-1.648 1.647A9 9 0 1 1 25.5 33.25a.75.75 0 0 0 0 1.5 10.5 10.5 0 0 0 2.048-20.798Z"
        fill="#79EEEB"
      />
    </svg>
  );
};

export default Replay;
