import { AuthContext } from 'hooks';
import { useLocalStorage } from 'react-use';

const AuthProvider = ({ children }) => {
  const [accessToken, setAccessToken] = useLocalStorage('accessToken', '', {
    raw: true,
  });
  const [refreshToken, setRefreshToken] = useLocalStorage('refreshToken', '', {
    raw: true,
  });

  const handleUpdate = ({ accessToken, refreshToken }) => {
    setAccessToken(accessToken);
    setRefreshToken(refreshToken);
  };

  const handleReset = () => {
    setAccessToken('');
    setRefreshToken('');
  };

  return (
    <AuthContext.Provider
      value={{
        accessToken,
        refreshToken,
        logout: handleReset,
        onUpdate: handleUpdate,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
