import { Stack } from '@tymate/margaret';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { GET_ACTUALITIES_PODCASTS } from 'api';
import { useQuery } from '@apollo/client';
import { CategoryCard } from 'components';

const OrganizationWrapper = styled(Stack).attrs({ size: 'full' })`
  display: grid;
  row-gap: 20px;
  column-gap: 20px;
  grid-template-areas:
    'firstActu firstActu'
    'secondActu thirdActu';

  ${({ theme }) => theme.media.tablet`
      grid-template-areas:'firstActu secondActu thirdActu';
      grid-template-columns: 2fr 145px 145px;
  `};
`;

const ActuWrapper = styled(Stack).attrs({ paddingTop: 1 })`
  display: grid;
  row-gap: 20px;
  column-gap: 20px;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 0;

  ${({ theme }) => theme.media.tablet`
    grid-template-columns: repeat(4, 145px);
  `};
`;

const Organization = () => {
  const { t } = useTranslation('home');

  const { data: actualitiesPodcasts } = useQuery(GET_ACTUALITIES_PODCASTS, {
    variables: {
      slug: 'actualities',
    },
  });

  const podcasts = [
    ...(actualitiesPodcasts?.category?.homePodcasts?.edges || []),
  ];

  return (
    <>
      <OrganizationWrapper>
        <CategoryCard
          backgroundImage={podcasts?.[0]?.node?.image?.url}
          to={`/actualities/${podcasts?.[0]?.node?.id}`}
          gridArea="firstActu"
          withTitle
          title={podcasts?.[0]?.node?.title}
        />

        <CategoryCard
          backgroundImage={podcasts?.[1]?.node?.image?.url}
          title={podcasts?.[1]?.node?.title}
          gridArea="secondActu"
          to={
            Boolean(podcasts?.[1]?.node?.category === t('team.title'))
              ? 'team'
              : `/actualities/${podcasts?.[1]?.node?.id}`
          }
          withTitle
        />

        <CategoryCard
          backgroundImage={podcasts?.[2]?.node?.image?.url}
          title={podcasts?.[2]?.node?.title}
          gridArea="thirdActu"
          to={
            Boolean(podcasts?.[2]?.node?.category === t('team.title'))
              ? 'team'
              : `/actualities/${podcasts?.[2]?.node?.id}`
          }
          withTitle
        />
      </OrganizationWrapper>

      {podcasts.slice(3, podcasts?.length).length > 0 && (
        <ActuWrapper>
          {podcasts.slice(3, podcasts?.length).map(({ node }, index) => (
            <CategoryCard
              key={node?.id ?? index}
              backgroundImage={node?.image?.url}
              title={node?.title}
              to={
                Boolean(node?.category === t('team.title'))
                  ? 'team'
                  : `/actualities/${node?.id}`
              }
              withTitle
            />
          ))}
        </ActuWrapper>
      )}
    </>
  );
};

export default Organization;
