import { Main, TargetsTag, Wrapper } from 'ui';
import { Button, OrganizationHeader } from 'components';
import { useApp, useLocale } from 'hooks';
import { Spinner, Stack, Text } from '@tymate/margaret';
import BackButton from 'components/BackButton';
import { useQuery } from '@apollo/client';
import { GET_PODCAST } from 'api';
import { capitalize } from 'lodash';
import { formatDateToMonth } from 'utils';
import { useTranslation } from 'react-i18next';
import ActualityItem from 'components/ActualityItem';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { IcTarget } from 'components/Icons';

const Actualities = () => {
  const { organization } = useApp();
  const { t } = useTranslation('actualities');
  const { id } = useParams();
  const { locale } = useLocale();

  const { data, loading } = useQuery(GET_PODCAST, {
    variables: { podcastId: id },
  });

  const [showMore, setShowMore] = useState(false);

  const podcast = data?.podcast;

  const audios = podcast?.audios?.edges || [];

  const audiosGroupedByMonth = audios.reduce((acc, pilot) => {
    const date = formatDateToMonth(pilot.node.createdAt, locale);
    if (acc.some(value => value['title'] === date)) {
      acc.find(value => value['title'] === date).data.push(pilot);
    } else {
      acc.push({ title: date, data: [pilot] });
    }
    return acc;
  }, []);

  const showMoreButton =
    podcast?.longDescription?.length > 150 || podcast?.targeted;

  const areas = podcast?.areas?.edges ?? [];
  const departments = podcast?.departments?.edges ?? [];
  const services = podcast?.services?.edges ?? [];

  const targets = [...areas, ...departments, ...services];

  return (
    <>
      <OrganizationHeader
        backgroundImage={organization?.banner?.url}
        organization={organization}
        withText
        isActualityPodcast
      />

      <Main>
        <Wrapper>
          <Stack size="full" direction="column">
            <Stack>
              <BackButton />
            </Stack>
            <Text type="h1" paddingBottom={1}>
              {podcast?.title}
            </Text>

            {showMore ? (
              <Text color="textLight" marginBottom={2}>
                {Boolean(podcast?.longDescription) && podcast?.longDescription}
              </Text>
            ) : (
              <Stack direction="column" gap={0.5}>
                <Text color="textLight">
                  {Boolean(podcast?.longDescription) &&
                    `${podcast?.longDescription?.substring(0, 150)}`}
                  {podcast?.longDescription?.length > 150 ? '...' : null}
                </Text>
                {podcast?.targeted && <IcTarget white />}
              </Stack>
            )}

            {showMore && podcast?.targeted && (
              <Stack direction="column" gap={1} marginBottom={1}>
                <Stack gap={0.5}>
                  <IcTarget white />
                  <Text type="body" fontWeight={700}>
                    {t('accessibleTo')}
                  </Text>
                </Stack>
                <Stack gap={0.5}>
                  {(targets ?? []).map(({ node }) => (
                    <TargetsTag key={node?.id}>
                      {node?.title ?? node?.displayName}
                    </TargetsTag>
                  ))}
                </Stack>
              </Stack>
            )}

            {showMoreButton && (
              <Stack size="full" alignX="flex-end">
                <Button onClick={() => setShowMore(!showMore)}>
                  <Text color="primary">
                    {showMore ? t('showLess') : t('showMore')}
                  </Text>
                </Button>
              </Stack>
            )}

            {loading ? (
              <Spinner />
            ) : (
              audiosGroupedByMonth.map(node => (
                <Stack direction="column" gutterSize={1} key={node?.id}>
                  <Text type="h3" marginTop={1}>
                    {capitalize(node.title)}
                  </Text>
                  {node.data.map(actuality => (
                    <ActualityItem
                      actuality={actuality}
                      key={actuality?.node?.id}
                    />
                  ))}
                </Stack>
              ))
            )}
          </Stack>
        </Wrapper>
      </Main>
    </>
  );
};

export default Actualities;
