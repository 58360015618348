import { gql } from '@apollo/client';

export const GET_ORGANIZATION_DO_NOT_MISSES = gql`
  query organizationDoNotMisses {
    currentOrganization {
      doNotMisses(first: 5) {
        edges {
          node {
            id
            audio {
              id
              title
              description
              podcast {
                id
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_TEAM_PRESENTATIONS = gql`
  query getTeamPresentations($serviceIds: [ID!], $areaIds: [ID!]) {
    currentOrganization {
      id
      presentations(
        areaIds: $areaIds
        serviceIds: $serviceIds
        order: { column: CREATED_AT, direction: DESC }
      ) {
        edges {
          node {
            id
            duration
            hlsUrl
            createdAt
            listening {
              id
              listened
            }
            user {
              id
              firstName
              lastName
              job
              area {
                id
                displayName
              }
              service {
                id
                title
              }
              avatar(size: medium_section) {
                id
                url
              }
            }
          }
        }
      }
    }
  }
`;
