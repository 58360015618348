import styled from 'styled-components';
import { Text, ButtonReset, useMargaret } from '@tymate/margaret';
import { Stack } from '@tymate/margaret';
import {
  IcPlayBack,
  IcPlayForward,
  IcPlay,
  IcPause,
  IcSoundDecrease,
  IcSoundIncrease,
} from 'components/Icons';
import placeholder from 'images/placeholder.png';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { formatDurationEmbed } from 'utils';
import { usePlayer } from 'hooks';

const Image = styled.img`
  min-width: 60px;
  width: 60px;
  height: 60px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  background-color: ${({ theme }) => theme.backgroundLight};
  object-fit: cover;

  ${({ theme }) => theme.media.embed`
  min-width: 90px;
  width: 90px;
  height: 90px;
    `};

  ${({ theme }) => theme.media.desktop`
  min-width: 112px;
  width: 112px;
  height: 112px;
    `};
`;

const EmbedWrapper = styled(Stack).attrs({
  size: 'full',
  padding: 1,
  gap: 1,
  alignX: 'space-between',
})`
  background: rgb(19, 20, 20);
  position: fixed;
  bottom: 0;
`;

const PrimaryButton = styled(ButtonReset)`
  font-size: 36px;
  ${({ theme }) => theme.media.tablet`
  font-size: 40px;
    `};
  ${({ theme }) => theme.media.desktop`
  font-size: 48px;
    `};
`;

const ControlButton = styled(ButtonReset)`
  font-size: 20px;
  color: ${({ theme }) => theme.textLighter};
  &:hover {
    color: ${({ theme }) => theme.textLight};
  }

  ${({ theme }) => theme.media.embed`
  font-size: 22px;
    `};

  ${({ theme }) => theme.media.desktop`
  font-size: 24px;
    `};
`;

const AudioPlayer = () => {
  const {
    track,
    currentAudio,
    skipBackward,
    togglePlayPause,
    isPlaying,
    skipForward,
    trackProgress,
    volume,
    handleVolume,
    duration,
    scrub,
    scrubEnd,
  } = usePlayer();

  const { isMobile } = useMargaret();

  if (!Boolean(track) || !Boolean(currentAudio)) {
    return null;
  }

  return (
    <EmbedWrapper>
      <Stack gap={1} alignY="center">
        <Image
          src={track?.image || placeholder}
          alt={track?.podcastTitle || ''}
        />

        {!isMobile && (
          <Stack direction="column" gap={0.25} width="300px">
            <Text type="subtitle" fontWeight="bold">
              {track?.podcastTitle}
            </Text>

            <Text type="body" color="primary">
              {track?.title}
            </Text>
          </Stack>
        )}
      </Stack>

      <Stack
        size="full"
        direction="column"
        alignX="center"
        style={{ maxWidth: 500 }}
      >
        <Stack gap={2} paddingBottom={0.5}>
          <ControlButton onClick={skipBackward}>
            <IcPlayBack colorFill="#fff" fillOpacity={0.87} />
          </ControlButton>

          <PrimaryButton
            onClick={() => togglePlayPause()}
            aria-label={isPlaying ? 'Pause' : 'Play'}
          >
            {isPlaying ? (
              <IcPause
                colorCircleFill="rgba(0, 170, 255, 0.16)"
                colorFill="var(--ui-primary)"
                size={48}
              />
            ) : (
              <IcPlay
                colorCircleFill="rgba(0, 170, 255, 0.16)"
                colorFill="var(--ui-primary)"
                size={48}
              />
            )}
          </PrimaryButton>

          <ControlButton onClick={skipForward}>
            <IcPlayForward colorFill="#fff" fillOpacity={0.87} />
          </ControlButton>
        </Stack>

        <Stack alignY="center" size="full" gap={0.5}>
          <Slider
            value={trackProgress}
            step={1}
            min={0}
            max={duration ? duration : track?.duration || 0}
            onChange={scrub}
            onAfterChange={scrubEnd}
            trackStyle={{
              backgroundColor: 'var(--ui-primary)',
            }}
            handleStyle={{ display: 'none' }}
            railStyle={{ backgroundColor: 'var(--ui-separator)' }}
          />
          <div style={{ width: 'max-content' }}>
            <Text
              type="footnote"
              color="textLight"
              style={{ width: 'max-content' }}
            >
              {formatDurationEmbed(trackProgress)}
              {' / '}
              {formatDurationEmbed(track?.duration || 0)}
            </Text>
          </div>
        </Stack>
      </Stack>
      {!isMobile && (
        <Stack gap={0.25} alignY="center" size="full" style={{ maxWidth: 160 }}>
          <div>
            <IcSoundDecrease size={22} />
          </div>
          <Slider
            defaultValue={1}
            value={volume}
            min={0}
            max={100}
            step={1}
            onChange={handleVolume}
            trackStyle={{
              backgroundColor: 'var(--ui-primary)',
            }}
            handleStyle={{ display: 'none' }}
            railStyle={{ backgroundColor: 'var(--ui-separator)' }}
          />
          <div>
            <IcSoundIncrease size={22} />
          </div>
        </Stack>
      )}
    </EmbedWrapper>
  );
};

export default AudioPlayer;
