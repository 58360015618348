import { resetPassword } from 'api/auth';
import { Stack, Text } from '@tymate/margaret';
import { Button, Layout } from 'components';
import { useTranslation } from 'react-i18next';
import { TextField } from 'components/Fields';
import { useAuth } from 'hooks';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Navigate } from 'react-router-dom';
import { useSearchParams } from 'utils';

const ResetPassword = () => {
  const { onUpdate, accessToken } = useAuth();
  const [{ token }] = useSearchParams();
  const { t } = useTranslation(['passwordReset', 'errors']);

  const handleResetPassword = async values => {
    try {
      const { data } = await resetPassword({
        accessToken: token,
        password: values?.password,
      });
      onUpdate(data);
    } catch (err) {
      console.log({ err });
    }
  };

  if (Boolean(accessToken)) {
    return <Navigate to="/" />;
  }

  return (
    <Layout size="tiny">
      <Stack alignX="center" direction="column" gutterSize={2} size="full">
        <div>
          <Text type="h1" style={{ textAlign: 'center' }}>
            {t('passwordResetHint')}
          </Text>
        </div>

        <Formik
          initialValues={{ password: '', passwordConfirmation: '' }}
          validationSchema={Yup.object().shape({
            password: Yup.string().required(t('errors:required')).min(8),
            passwordConfirmation: Yup.string().oneOf(
              [Yup.ref('password'), null],
              t('errors:passwordDontMatch'),
            ),
          })}
          onSubmit={handleResetPassword}
        >
          {({ isSubmitting }) => (
            <Form style={{ width: '100%' }}>
              <Stack direction="column" alignX="center" gap={2}>
                <TextField
                  label={t('password')}
                  id="reset_password"
                  placeholder={t('passwordHint')}
                  name="password"
                  type="password"
                />
                <TextField
                  label={t('passwordConfirmation')}
                  id="reset-confirm"
                  placeholder={t('passwordHint')}
                  name="passwordConfirmation"
                  type="password"
                />
                <Button variant="primary" size="full" isLoading={isSubmitting}>
                  {t('button:passwordForgotten')}
                </Button>
              </Stack>
            </Form>
          )}
        </Formik>
      </Stack>
    </Layout>
  );
};

export default ResetPassword;
