import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Button, Stack, Text } from '@tymate/margaret';
import appStoreBadge from 'images/badge-app-store.png';
import googlePlayBadge from 'images/badge-google-play.png';
import { usePlayer } from 'hooks';

const Main = styled(Stack).attrs()`
  background: #060d0d;
  flexgrow: 1;
  alignx: 'center';

  ${({ playerIsOpen }) =>
    playerIsOpen &&
    css`
      margin-bottom: ${({ theme }) => theme.spacing(6)};
    `}
`;

const Footer = styled(Stack).attrs({
  direction: 'column',
  flex: 1,
  gap: 1,
  alignX: 'center',
  alignY: 'flex-end',
  paddingVertical: { default: 2, tablet: 3 },
  paddingHorizontal: { default: 0, tablet: 3 },
})``;

const StoresLink = ({ onDismiss, onClick }) => {
  const { t } = useTranslation('stores');
  const { track } = usePlayer();

  return (
    <Main playerIsOpen={Boolean(track)}>
      <Footer>
        <Text color="textLight" type="callout" style={{ fontSize: 14 }}>
          {t('info')}
        </Text>
        <Stack direction="row" gap={1.5}>
          <Button
            onClick={() =>
              window.location.replace(
                'https://apps.apple.com/fr/app/natif/id1603449179',
              )
            }
          >
            <img src={appStoreBadge} alt="app-store-badge" width={120} />
          </Button>
          <Button
            onClick={() =>
              window.location.replace(
                'https://play.google.com/store/apps/details?id=com.natifapp',
              )
            }
          >
            <img src={googlePlayBadge} alt="app-store-badge" width={120} />
          </Button>
        </Stack>
      </Footer>
    </Main>
  );
};

export default StoresLink;
