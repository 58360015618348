import React from 'react';
import { useTranslation } from 'react-i18next';
import { /*  Button, */ Button, Stack, Text } from '@tymate/margaret';
import { MiniCover } from 'ui';
import styled, { css } from 'styled-components';
import Calendar from './Icons/Calendar';
//import ThreeDots from './Icons/ThreeDots';
import { formatDate, formatPosition } from 'utils';
import Checkbox from './Icons/Checkbox';
import CheckboUnselected from './Icons/CheckboUnselected';
import { isAfter } from 'date-fns';
import Hourglass from './Icons/Hourglass';
import { useNavigate } from 'react-router-dom';

const ItemWrapper = styled(Stack).attrs({
  direction: 'column',
  gap: 0.5,
  marginTop: 1,
  paddingHorizontal: 1,
  paddingVertical: 0.5,
})`
  background-color: ${({ theme }) => theme.backgroundWhite};
  border-radius: 4px;

  ${({ theme }) => theme.media.tablet`
    grid-template-columns: repeat(5, 1fr);
  `};
`;

const Label = styled(Stack).attrs({
  paddingHorizontal: 1.2,
  paddingVertical: 0.2,
  alignX: 'center',
})`
  width: 130px;
  border: 1px solid ${({ theme }) => theme.adminPublished};
  border-radius: 100px;

  ${({ planned }) =>
    planned &&
    css`
      border: 1px solid ${({ theme }) => theme.adminPlanned};
    `}

  ${({ draft }) =>
    draft &&
    css`
      border: 1px solid ${({ theme }) => theme.adminDraft};
    `}
`;

const AdminAudioItem = ({ id, audio, isSelected, setSelected }) => {
  const { t } = useTranslation('adminPodcast');
  const navigate = useNavigate();

  const isPlanned = () =>
    audio.publishedAt
      ? isAfter(new Date(audio.publishedAt), new Date())
      : false;

  return (
    <ItemWrapper>
      <Stack alignY="center" size="full" gap={0.5}>
        <Button onClick={() => setSelected(audio?.id)}>
          {isSelected ? <Checkbox /> : <CheckboUnselected />}
        </Button>
        <Stack
          alignY="center"
          size="full"
          gap={0.5}
          onClick={() => navigate(`/admin/${id}/newAudio?audio=${audio.id}`)}
          style={{ cursor: 'pointer' }}
        >
          <MiniCover src={audio?.cover?.url ?? audio?.podcast?.image?.url} />
          <Stack direction="column" flex={1}>
            <Text type="body" color="textDark">
              {audio?.title}
            </Text>
            <Stack gap={0.5} alignY="center">
              <Text color="textLighterOnLightTheme" type="callout">
                <Calendar /> {formatDate(audio?.publishedAt)}
              </Text>
              <Text color="textLighterOnLightTheme" type="callout">
                <Hourglass /> {formatPosition(audio?.duration * 1000)}
              </Text>
            </Stack>
          </Stack>
        </Stack>

        <Label planned={isPlanned()} draft={audio.draft}>
          <Text
            color={
              audio.draft
                ? 'adminDraft'
                : isPlanned()
                ? 'adminPlanned'
                : 'adminPublished'
            }
          >
            {audio.draft
              ? t('draft')
              : isPlanned()
              ? t('planned')
              : t('published')}
          </Text>
        </Label>
        {/* 
        <Button onClick={() => {}}>
          <ThreeDots />
        </Button> */}
      </Stack>
    </ItemWrapper>
  );
};

export default AdminAudioItem;
