import * as React from 'react';

const SoundDecrease = props => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ ...props.style, fontSize: props.size }}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.548 3.759A2.25 2.25 0 0120 6v12a2.25 2.25 0 01-3.575 1.819l-6.393-4.069H7.25A2.25 2.25 0 015 13.5v-3a2.25 2.25 0 012.25-2.25h2.782l6.393-4.069a2.25 2.25 0 011.123-.422zm.538 1.57a.75.75 0 01.414.67V18a.75.75 0 01-1.2.6.765.765 0 00-.047-.033l-6.6-4.2a.75.75 0 00-.403-.117h-3a.75.75 0 01-.75-.75v-3a.75.75 0 01.75-.75h3a.75.75 0 00.403-.117l6.6-4.2A.759.759 0 0017.3 5.4a.75.75 0 01.786-.071z"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.25 8.25A.75.75 0 0111 9v6a.75.75 0 01-1.5 0V9a.75.75 0 01.75-.75z"
        fill="#fff"
      />
    </svg>
  );
};

export default SoundDecrease;
