import { Header } from 'components';
import { BackgroundImage, Cover } from 'ui';

const PodcastHeader = ({ image }) => {
  return (
    <BackgroundImage backgroundImage={image}>
      <Header homeHeader>
        <Cover backgroundImage={image} />
      </Header>
    </BackgroundImage>
  );
};

export default PodcastHeader;
