import { LightWrapper, MainLight } from 'ui';
import { Button, Header } from 'components';
import { useApp } from 'hooks';
import { Spinner, Stack, Text } from '@tymate/margaret';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { GET_BO_PODCAST } from 'api';
import AdminAudioItem from 'components/AdminAudioItem';
import { useState } from 'react';
import Add from 'components/Icons/Add';
import Trash from 'components/Icons/Trash';
import DeleteModal from 'components/DeleteModal';
import AdminBackButton from 'components/AdminBackButton';
import { ToastContainer } from 'react-toastify';
import { DELETE_AUDIBLE } from 'api/audio';

const AdminPodcast = () => {
  const { id } = useParams();

  const { organization } = useApp();
  const { t } = useTranslation('adminPodcast');
  const [selectedIds, setSelectedIds] = useState([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const [deleteAudible] = useMutation(DELETE_AUDIBLE);

  const { data, loading, refetch } = useQuery(GET_BO_PODCAST, {
    variables: { podcastId: id },
  });

  const podcast = data?.podcast;
  const audiosEdges = podcast?.audios?.edges || [];

  /*   const handleToggleAllIds = e => {
    setSelectedIds(audiosEdges.map(({ node }) => node?.id));
  }; */

  const handleToggleId = id => {
    if (selectedIds.some(selectedId => selectedId === id)) {
      setSelectedIds(selectedIds.filter(selectedId => selectedId !== id));
    } else {
      setSelectedIds([...selectedIds, id]);
    }
  };

  const onDelete = async () => {
    try {
      await Promise.all(
        selectedIds.map(async id => {
          await deleteAudible({
            variables: { input: { audibleId: id } },
          });
        }),
      );
    } catch (err) {
      console.log(err);
    }
    refetch();

    setSelectedIds([]);
  };

  return (
    <>
      <Header customLogo={organization?.logo?.url} color="#060d0d" isAdmin />

      <MainLight>
        <LightWrapper>
          <Stack direction="column">
            <Stack>
              <AdminBackButton />
            </Stack>
            <Text type="h1" color="textDark">
              {t('title')}
            </Text>
            <Stack alignX="space-between" justifyContent="start">
              {/*   <Button
                onClick={() => handleToggleAllIds()}
                style={{ marginLeft: -8 }}
              >
                <Text type="body" color="textDark">
                  {t('selectAll')}
                </Text>
              </Button> */}

              <div />

              <Stack alignY="center" gap={2}>
                {
                  <Button
                    variant="simple"
                    onClick={() =>
                      selectedIds.length !== 0 && setIsDeleteModalOpen(true)
                    }
                  >
                    <Trash />
                  </Button>
                }

                <Button variant="adminPrimary" to={`/admin/${id}/newAudio`}>
                  <Add /> {t('add')}
                </Button>
              </Stack>
            </Stack>

            {loading ? (
              <Spinner />
            ) : (
              audiosEdges.map(({ node }, index) => (
                <AdminAudioItem
                  key={index}
                  id={id}
                  audio={node}
                  isSelected={selectedIds.some(
                    selectedId => selectedId === node?.id,
                  )}
                  setSelected={handleToggleId}
                />
              ))
            )}
          </Stack>
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </LightWrapper>

        <DeleteModal
          isOpen={isDeleteModalOpen}
          onRequestClose={() => setIsDeleteModalOpen(false)}
          onDelete={onDelete}
        />
      </MainLight>
    </>
  );
};

export default AdminPodcast;
