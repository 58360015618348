import styled from 'styled-components';
import { Avatar as AvatarMgt } from '@tymate/margaret';

const Avatar = styled(AvatarMgt)`
  background-color: ${({ theme }) => theme.backgroundLight};
  color: ${({ theme }) => theme.primary};
  border: 2px solid ${({ theme }) => theme.primary};
`;

export default Avatar;
