import styled, { css } from 'styled-components';
import {
  ButtonReset,
  Stack,
  PopoverItemButton as PopoverItemButtonMgt,
} from '@tymate/margaret';

// 👇 Below element is used to prevent broken layout with
//    ellipses on flex children.
//    (See https://css-tricks.com/flexbox-truncated-text/)
export const FlexTruncatedText = styled.div`
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;

  ${({ isPlaceholder }) =>
    isPlaceholder &&
    css`
      color: ${({ theme }) => theme.textLight};
    `}
`;

export const PopoverMenu = styled.div`
  position: absolute;
  left: 0;
  top: calc(100% + ${({ theme }) => theme.spacing(0.25)});
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.2), inset 0 0 0 1px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  overflow: hidden;
  min-width: 100%;
  width: 200px;
  background-color: ${({ theme }) => theme.background};

  ${({ size }) =>
    Boolean(size) &&
    `
      width: ${size}px
    `};

  ${({ alignment }) =>
    alignment === 'right' &&
    `
      left: auto;
      right: 0
      `};
`;

export const PopoverItem = styled(ButtonReset)`
  height: 40px;
  text-align: left;
  padding-left: ${({ theme }) => theme.spacing()};
  padding-right: ${({ theme }) => theme.spacing()};
  transition: background-color 150ms ease;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &:hover {
    background-color: ${({ theme }) => theme.backgroundLight};
  }

  ${({ isActive, theme }) =>
    isActive &&
    `
      font-weight: 500;
      background-color: ${theme.backgroundLight};
    `}

  ${({ disabled, theme }) =>
    disabled &&
    `
      color: ${theme.gray};
      background-color: ${theme.separatorLight};
      svg {
        color: ${theme.gray};
      }
      &:hover {
        background-color: ${theme.separatorLight};
      }

      &, * {
        cursor: not-allowed;
        pointer-events: none;
      }
    `}
`;

export const PopoverItemButton = styled(PopoverItemButtonMgt)`
  color: #fff;
`;

export const PopoverHeader = styled(Stack).attrs({
  alignY: 'center',
  paddingHorizontal: 1,
  paddingVertical: 2,
  gutterSize: 0.5,
})`
  ${({ theme }) => theme.fontStyles.body};
  color: ${({ theme }) => theme.textLight};
  height: 40px;
`;

export const Shape = styled.div`
  padding: ${({ theme }) => theme.spacing(2)};
`;

export const Banner = styled.div`
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: -180px;
  border-radius: 12px;
  margin: auto;
  height: 300px;
  max-width: 400px;
  width: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%),
    url(${({ backgroundImage }) => backgroundImage});
  background-position: center center;
  background-size: cover;

  ${({ theme }) => theme.media.tablet`
    max-width: 660px;
  `};
`;

export const Cover = styled.div`
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: -90px;
  border-radius: 12px;
  margin: auto;
  height: 180px;
  width: 180px;
  background: url(${({ backgroundImage }) => backgroundImage});
  background-size: cover;
`;

export const BackgroundImage = styled(Stack).attrs({ size: 'full' })`
  position: relative;
  height: 300px;
  background: linear-gradient(
      0deg,
      ${({ theme }) => theme.textLighterOnLightTheme},
      ${({ theme }) => theme.textLighterOnLightTheme}
    ),
    url(${({ backgroundImage }) => backgroundImage});
  background-size: cover;
`;

export const Main = styled(Stack).attrs({
  direction: 'column',
  alignX: 'center',
})`
  overflow-y: auto;
  background: #060d0d;
  min-height: 100vh;
`;

export const MainLight = styled(Stack).attrs({
  direction: 'column',
  alignX: 'center',
})`
  overflow-y: auto;
  background: hsla(180, 11%, 96%, 1);
  min-height: 100vh;
`;

export const MiniCover = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 8px;

  ${({ size }) =>
    Boolean(size) &&
    `
      width: ${size}px;
      height: ${size}px;
    `};
`;

export const Wrapper = styled(Stack).attrs({
  direction: 'column',
  size: 'full',
  gutterSize: 4,
  paddingBottom: 2,
})`
  overflow-y: auto;
  margin-top: 120px;
  padding-left: 10px;
  padding-right: 10px;

  ${({ theme }) => theme.media.tablet`
    max-width: 660px;
  `};

  ${({ theme }) => theme.media.desktop`
    max-width: 660px;
  `};
`;

export const LightWrapper = styled(Stack).attrs({
  direction: 'column',
  size: 'full',
  gutterSize: 4,
  paddingBottom: 2,
})`
  overflow-y: auto;
  margin-top: 10px;
  padding-left: 10px;
  padding-right: 10px;

  ${({ theme }) => theme.media.tablet`
    max-width: 800px;
  `};

  ${({ theme }) => theme.media.desktop`
    max-width: 1160px;
  `};
`;

export const Badge = styled(Stack).attrs({
  padding: 0.25,
  marginTop: 0.75,
})`
  background-color: ${({ theme }) => theme.backgroundTag};
  border-radius: 8px;
  color: ${({ theme }) => theme.primary};
  width: fit-content;
`;

export const Tag = styled(Stack).attrs({
  padding: 0.15,
  marginTop: 0.75,
})`
  background-color: ${({ theme }) => theme.backgroundTag};
  border-radius: 8px;
  color: ${({ theme }) => theme.primary};
  width: fit-content;
`;

export const TargetsTag = styled(Stack).attrs({
  paddingHorizontal: 0.4,
})`
  border-radius: 8px;
  color: ${({ theme }) => theme.textCallout};
  border: 1px solid ${({ theme }) => theme.separator};
`;
