import styled, { css } from 'styled-components';
import logo from 'images/logo-header.svg';
import { Link } from 'react-router-dom';
import {
  Stack,
  Dropdown,
  Text,
  PopoverMenu as PopoverMenuMgt,
} from '@tymate/margaret';
import { Avatar } from 'components';
import { useAuth, useApp } from 'hooks';
import { MdEdit } from 'react-icons/md';
import { IcPower } from './Icons';
import { PopoverItemButton, PopoverHeader } from 'ui';
import { useTranslation } from 'react-i18next';

const PopoverMenu = styled(PopoverMenuMgt)`
  width: 250px;
  background-color: ${({ theme }) => theme.backgroundPopover};
`;

const Wrapper = styled(Stack).attrs({
  alignX: 'space-between',
  alignY: 'flex-start',
  padding: 2,
  paddingTop: 1,
  paddingBottom: 1,
  size: 'full',
})`
  ${({ homeHeader }) =>
    homeHeader &&
    css`
      backdrop-filter: blur(48px);
    `};

  ${({ isAdmin }) =>
    isAdmin &&
    css`
      background-color: #cbcccd;
    `};
`;

const Header = ({ homeHeader, children, customLogo, isAdmin }) => {
  const { user } = useApp();
  const { logout } = useAuth();
  const { t } = useTranslation('settings');

  return (
    <Wrapper homeHeader={homeHeader} isAdmin={isAdmin}>
      <Link to="/">
        <img
          alt="logo"
          src={customLogo ?? logo}
          style={{ maxHeight: '48px' }}
        />
      </Link>

      {Boolean(user) && (
        <Dropdown trigger={<Avatar imageUrl={user?.avatar?.url} />}>
          <PopoverMenu alignment="right">
            <PopoverHeader>
              <Avatar size="small" imageUrl={user?.avatar?.url} />
              <Stack direction="column">
                <Text color="white" fontWeight={700}>
                  {user?.firstName} {user?.lastName}
                </Text>
                <Text style={{ fontSize: 14 }} color="white">
                  {user?.email}
                </Text>
              </Stack>
            </PopoverHeader>
            <PopoverItemButton as={Link} to="/profile">
              <MdEdit size={18} /> {t('editMyProfile')}
            </PopoverItemButton>
            {user?.role === 'admin' && (
              <PopoverItemButton as={Link} to="/admin">
                {t('adminMode')}
              </PopoverItemButton>
            )}
            <PopoverItemButton onClick={logout}>
              <IcPower size={18} /> {t('logout')}
            </PopoverItemButton>
          </PopoverMenu>
        </Dropdown>
      )}
      {children}
    </Wrapper>
  );
};

export default Header;
