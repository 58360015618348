import { firstLogin } from 'api/auth';
import { Stack, Text } from '@tymate/margaret';
import { Navigate, useLocation } from 'react-router-dom';
import { Button } from 'components';
import { useTranslation } from 'react-i18next';
import { TextField } from 'components/Fields';
import { useAuth } from 'hooks';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Layout from 'components/Layout';
import { useState } from 'react';
import { formatRestError } from 'utils';
import { ErrorMessage } from 'ui/forms';

const ActivateAccount = () => {
  const { onUpdate, accessToken } = useAuth();
  const { t } = useTranslation([
    'accountActivation',
    'button',
    'errors',
    'login',
  ]);
  const location = useLocation();

  const [step, setStep] = useState(1);

  const handleFirstLogin = async (values, { setStatus }) => {
    try {
      const { data } = await firstLogin(values);
      onUpdate(data);
      setStep(2);
    } catch (err) {
      setStatus(t(`errors:${formatRestError(err)}`));
    }
  };

  const EmailStep = ({ status }) => (
    <>
      <div>
        <Text type="h1">{t('firstLogin')}</Text>
      </div>
      <div>
        <Text
          type="body"
          style={{
            textAlign: 'center',
          }}
        >
          {t('firstLoginHint')}
        </Text>
      </div>
      <Stack direction="column" alignX="center" gap={2}>
        <TextField
          label={t('login:email')}
          id="login-email"
          autoComplete="email"
          placeholder=""
          name="email"
          type="email"
        />
        {Boolean(status) && <ErrorMessage>{status}</ErrorMessage>}

        <Button type="submit" variant="primary" size="full">
          {t('button:activateAccount')}
        </Button>
        <Button variant="outline" type="button" to="/login">
          {t('button:back')}
        </Button>
      </Stack>
    </>
  );

  if (Boolean(accessToken)) {
    return <Navigate to="/" />;
  }

  return (
    <Layout size="tiny">
      <Formik
        initialValues={{ email: location?.state.email }}
        validationSchema={Yup.object().shape({
          email: Yup.string().email().required(t('errors:required')),
        })}
        onSubmit={handleFirstLogin}
      >
        {({ status }) => (
          <Form style={{ width: '100%' }}>
            <Stack alignX="center" direction="column" gutterSize={2}>
              {step === 1 && <EmailStep status={status} />}
              {step === 2 && (
                <div>
                  <Text type="h1">{t('firstLoginSuccess')}</Text>
                </div>
              )}
            </Stack>
          </Form>
        )}
      </Formik>
    </Layout>
  );
};

export default ActivateAccount;
