const AddPicture = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
    >
      <path
        d="M24.6001 16.5562H10.1186"
        stroke="#1A1A1A"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17.3594 23.797V9.31543"
        stroke="#1A1A1A"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M31.2676 16.458C31.2676 24.3281 24.8876 30.708 17.0176 30.708C9.14752 30.708 2.76758 24.3281 2.76758 16.458C2.76758 8.58795 9.14752 2.20801 17.0176 2.20801C24.8876 2.20801 31.2676 8.58795 31.2676 16.458Z"
        stroke="#1A1A1A"
        stroke-width="1.5"
      />
    </svg>
  );
};

export default AddPicture;
