import { Stack, Text } from '@tymate/margaret';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { getCategoryTitleKey } from 'api';
import { Link } from 'react-router-dom';

const BackgroundImageCard = styled(Stack).attrs({
  size: 'full',
  alignY: 'flex-end',
  padding: 1,
})`
  height: 145px;
  grid-area: ${({ gridArea }) => gridArea};
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 35.42%,
      rgba(0, 0, 0, 0.85) 100%
    ),
    url('${({ backgroundImage }) => backgroundImage}');
  background-size: cover;
  border-radius: 12px;
  margin: 0;
  text-decoration: none;
  color: inherit;

  ${({ withoutGradient }) =>
    withoutGradient &&
    css`
      background-image: url('${({ backgroundImage }) => backgroundImage}');
    `}
`;

const AdminCard = ({
  category,
  backgroundImage,
  withTitle,
  gridArea,
  title,
  to,
  kind,
  withoutGradient = false,
}) => {
  const { t } = useTranslation('admin');

  const getKindColor = key => {
    switch (key) {
      case 'public':
        return '#79EEA8';
      case 'restricted':
        return '#79EEEB';
      case 'private':
        return '#D2193C';
      default:
        break;
    }
  };

  return (
    <>
      <BackgroundImageCard
        backgroundImage={backgroundImage}
        gridArea={gridArea}
        as={Link}
        to={to}
        withoutGradient={withoutGradient}
        style={{ position: 'relative' }}
      >
        {withTitle && (
          <Stack flex={1} alignX="center">
            <Text
              type="body"
              fontWeight={700}
              style={{
                textAlign: 'center',
              }}
            >
              {category ? t(getCategoryTitleKey(category?.node?.slug)) : title}
            </Text>
          </Stack>
        )}
        {kind && (
          <Text
            style={{
              position: 'absolute',
              top: 10,
              left: 0,
              paddingLeft: 10,
              paddingRight: 10,
              borderTopRightRadius: 4,
              borderBottomRightRadius: 4,
              backgroundColor: getKindColor(kind),
            }}
          >
            {t(`kind.${kind}`)}
          </Text>
        )}
      </BackgroundImageCard>
    </>
  );
};

export default AdminCard;
