import { Stack, Text } from '@tymate/margaret';
import styled from 'styled-components';
import { Avatar } from 'components';
import { formatDuration, formatDate } from 'utils';
import { MdCheckCircleOutline } from 'react-icons/md';
import { Badge } from 'ui';
import { useTranslation } from 'react-i18next';
import { capitalize } from 'lodash';
import { usePlayer, useLocale } from 'hooks';
import PlayButton from './PlayButton';

const Card = styled(Stack).attrs({
  size: 'full',
  padding: 0.5,
  alignX: 'space-between',
})`
  background-color: ${({ theme }) => theme.backgroundTeamCard};
  border: 1px solid ${({ theme }) => theme.backgroundTeamCard};
  border-radius: 12px;
`;

const TeamItem = ({ presentation }) => {
  const { t } = useTranslation('misc');
  const { locale } = useLocale();

  const { setTrack, setCurrentAudio, currentAudio, togglePlayPause } =
    usePlayer();

  const audioChanged = currentAudio !== presentation?.id;

  const audioInfo = {
    podcastTitle: `${presentation?.user?.firstName} ${presentation?.user?.lastName}`,
    title: presentation?.user?.job,
    audioSrc: presentation?.hlsUrl ?? '',
    image: presentation?.user?.avatar?.url,
    duration: presentation?.duration,
  };

  const handlePlayButton = () => {
    setTrack(audioInfo);
    setCurrentAudio(presentation?.id);
    togglePlayPause();
  };

  return (
    <Stack direction="column">
      <Card>
        <Stack gutterSize={1}>
          <Avatar
            imageUrl={presentation?.user?.avatar?.url}
            firstName={presentation?.user?.firstName}
            lastName={presentation?.user?.lastName}
          />
          <Stack direction="column">
            <Text color="primary">
              {presentation?.user?.firstName} {presentation?.user?.lastName}
            </Text>
            <Text color="textCallout">{presentation?.user?.job}</Text>
            {Boolean(presentation?.user?.service) && (
              <Badge>{presentation?.user?.service?.title}</Badge>
            )}
          </Stack>
        </Stack>
        <Stack>
          <Stack direction="column" gutterSize={0.5} alignX="flex-end">
            {presentation?.listening?.listened ? (
              <Stack alignY="center" gutterSize={0.5}>
                <MdCheckCircleOutline color="#79EEEB" size={20} />
                <Text color="primary">{t('listened')}</Text>
              </Stack>
            ) : (
              <Text color="textCallout">
                {formatDuration(presentation?.duration, {
                  withSeconds: true,
                })}
              </Text>
            )}
            <PlayButton onClick={handlePlayButton} idChanged={audioChanged} />
          </Stack>
        </Stack>
      </Card>
      <Stack size="full" alignX="flex-end" paddingTop={0.5}>
        <Text color="textLight">
          {capitalize(formatDate(presentation?.createdAt, locale))}
        </Text>
      </Stack>
    </Stack>
  );
};

export default TeamItem;
