import { memo } from 'react';

const Target = ({ white }, ...props) => (
  <svg
    width="1.5em"
    height="1.5em"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g
      clipPath="url(#a)"
      stroke={white ? '#fff' : 'rgba(255, 255, 255, 0.6)'}
      strokeWidth={1.875}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M19.375 15.625v2.5a1.25 1.25 0 0 1-1.25 1.25h-2.5M15.625.625h2.5a1.25 1.25 0 0 1 1.25 1.25v2.5M.625 4.375v-2.5a1.25 1.25 0 0 1 1.25-1.25h2.5M4.375 19.375h-2.5a1.25 1.25 0 0 1-1.25-1.25v-2.5M10 10.938a3.437 3.437 0 1 0 0-6.875 3.437 3.437 0 0 0 0 6.875ZM15.305 15.938a5.625 5.625 0 0 0-10.609 0" />
    </g>
    <defs>
      <clipPath id="a">
        <path
          fill={white ? '#fff' : 'rgba(255, 255, 255, 0.6)'}
          d="M0 0h20v20H0z"
        />
      </clipPath>
    </defs>
  </svg>
);

export default memo(Target);
