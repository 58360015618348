import { checkEmail, signInCryptr } from 'api/auth';
import { Stack, Text } from '@tymate/margaret';
import { Button, Layout } from 'components';
import { useTranslation } from 'react-i18next';
import { TextField } from 'components/Fields';
import { useAuth } from 'hooks';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Navigate, useNavigate } from 'react-router-dom';
import { ErrorMessage } from 'ui/forms';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useCryptr } from '@cryptr/cryptr-react';

const Login = () => {
  const { t } = useTranslation(['login', 'errors']);
  const { onUpdate, accessToken } = useAuth();
  const { signInWithEmail } = useCryptr();
  const navigate = useNavigate();

  const handleLogin = async values => {
    try {
      const { data } = await checkEmail(values?.email);
      if (data.isCryptr) {
        signInWithEmail(values?.email);
      } else if (data.signupComplete) {
        navigate('/password', { state: { email: values?.email } });
      } else {
        navigate('/first-login', { state: { email: values?.email } });
      }
    } catch (err) {
      navigate('/not-found');

      handleCryptrLogin();
    }
  };

  const handleCryptrLogin = async accessToken => {
    try {
      const { data } = await signInCryptr(accessToken);
      onUpdate(data);
      return <Navigate to="/" />;
    } catch (err) {
      //todo add error login later
    }
  };

  if (Boolean(accessToken)) {
    return <Navigate to="/" />;
  }

  return (
    <Layout size="tiny">
      <Stack alignX="center" direction="column" gutterSize={2} size="full">
        <div>
          <Text type="h1" style={{ textAlign: 'center' }}>
            {t('welcome')}
          </Text>
        </div>
        <div>
          <Text
            type="body"
            fontWeight="bold"
            style={{
              textAlign: 'center',
              opacity: 0.6,
            }}
          >
            {t('welcomeHint')}
          </Text>
        </div>

        <Formik
          initialValues={{ email: '' }}
          validationSchema={Yup.object().shape({
            email: Yup.string().email().required(t('errors:required')),
          })}
          onSubmit={handleLogin}
        >
          {({ isSubmitting, status }) => (
            <Form style={{ width: '100%' }}>
              <Stack direction="column" alignX="center" gap={2}>
                <TextField
                  label={t('email')}
                  id="login-username"
                  autoComplete="email"
                  placeholder=""
                  name="email"
                  type="email"
                />

                {Boolean(status) && <ErrorMessage>{status}</ErrorMessage>}

                <Button variant="primary" size="full" isLoading={isSubmitting}>
                  {t('button:login')}
                </Button>
              </Stack>
            </Form>
          )}
        </Formik>

        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </Stack>
    </Layout>
  );
};

export default Login;
