import { Stack, Text } from '@tymate/margaret';
import { Button, Layout } from 'components';
import { useTranslation } from 'react-i18next';
import {
  TextField,
  TextareaField,
  SelectField,
  AvatarField,
} from 'components/Fields';
import { Formik, Form } from 'formik';
import { useApp, useLocale } from 'hooks';
import * as Yup from 'yup';
import { gql, useMutation } from '@apollo/client';
import { omit, pickBy } from 'lodash';

const UPDATE_USER = gql`
  mutation updateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      user {
        id
        bio
        email
        firstName
        lastName
        job
        role
        avatar {
          url
        }
      }
      errors {
        attribute
        message
        path
        error
      }
    }
  }
`;

const Profile = () => {
  const { t } = useTranslation(['profileEdition']);
  const { locale, onUpdateLocale, localeOptions } = useLocale();
  const { user, refetchUser, organization } = useApp();

  const [updateUser] = useMutation(UPDATE_USER);

  const handleSubmitForm = async values => {
    try {
      await updateUser({
        variables: {
          input: pickBy({
            ...omit(values, ['email', 'locale']),
            avatar: typeof values?.avatar === 'string' ? null : values.avatar,
          }),
        },
      });
      onUpdateLocale(values?.locale);
      refetchUser();
    } catch (err) {
      console.log({ err });
    }
  };

  const servicesOptions = (organization?.services || [])?.map(
    ({ title, id }) => ({
      label: title,
      value: id,
    }),
  );

  const areasOptions = (organization?.areas || [])?.map(
    ({ displayName, id }) => ({
      label: displayName,
      value: id,
    }),
  );

  const departmentsOptions = (organization?.departments || [])?.map(
    ({ displayName, id }) => ({
      label: displayName,
      value: id,
    }),
  );

  return (
    <Layout>
      <Stack direction="column" alignX="center">
        <div>
          <Text type="h1" style={{ textAlign: 'center' }}>
            {t('Information')}
          </Text>
        </div>

        <Formik
          onSubmit={handleSubmitForm}
          enableReinitialize
          initialValues={{
            firstName: user?.firstName ?? '',
            lastName: user?.lastName ?? '',
            email: user?.email ?? '',
            job: user?.job ?? '',
            bio: user?.bio ?? '',
            locale: locale,
            areaId: user?.area?.id ?? '',
            serviceId: user?.service?.id ?? '',
            avatar: user?.avatar?.url,
            departmentId: user?.department?.id ?? '',
          }}
          validationSchema={Yup.object().shape({
            firstName: Yup.string(),
            areaId: Yup.string().required(t('errors:required')),
            serviceId: Yup.string().required(t('errors:required')),
            departmentId: Yup.string().required(t('errors:required')),
          })}
        >
          {({ isSubmitting }) => (
            <Form>
              <Stack gap={2.5} direction="column" marginTop={2} alignX="center">
                <AvatarField
                  name="avatar"
                  label={t('choosePicture')}
                  size="huge"
                  url={user?.avatar?.url}
                />

                <Stack
                  gap={{ default: 2, tablet: 4 }}
                  direction={{ default: 'column', tablet: 'row' }}
                >
                  <Stack
                    direction="column"
                    gap={2}
                    alignY="flex-start"
                    size="full"
                  >
                    <TextField
                      label={t('lastName')}
                      id="login-lastName"
                      placeholder=""
                      name="lastName"
                    />
                    <TextField
                      label={t('firstName')}
                      id="login-firstName"
                      placeholder=""
                      name="firstName"
                    />
                    <TextField
                      label={t('email')}
                      id="login-username"
                      autoComplete="email"
                      placeholder=""
                      name="email"
                      type="email"
                      disabled
                    />
                    <TextField
                      label={t('job')}
                      id="login-job"
                      placeholder=""
                      name="job"
                    />
                    <SelectField
                      label={t('service')}
                      id="login-service"
                      placeholder={t('placeHolderService')}
                      name="serviceId"
                      options={servicesOptions}
                    />
                  </Stack>
                  <Stack
                    direction="column"
                    gap={2}
                    alignY="flex-start"
                    size="full"
                  >
                    <SelectField
                      label={t('secteur')}
                      id="login-secteur"
                      placeholder={t('placeHolderSecteur')}
                      name="areaId"
                      options={areasOptions}
                    />
                    <SelectField
                      label={t('department')}
                      id="login-depatment"
                      placeholder={t('placeHolderDepartment')}
                      name="departmentId"
                      options={departmentsOptions}
                    />
                    <SelectField
                      label={t('language')}
                      id="login-locale"
                      placeholder=""
                      name="locale"
                      options={localeOptions}
                    />
                    <TextareaField
                      label={t('bio')}
                      id="login-bio"
                      placeholder=""
                      name="bio"
                    />
                  </Stack>
                </Stack>
                <div>
                  <Button
                    variant="primary"
                    isLoading={isSubmitting}
                    type="submit"
                  >
                    {t('button:save')}
                  </Button>
                </div>
              </Stack>
            </Form>
          )}
        </Formik>
      </Stack>
    </Layout>
  );
};

export default Profile;
