const Play = props => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ ...props.style, fontSize: props.size }}
      {...props}
    >
      <circle
        cx={24}
        cy={24}
        r={24}
        fill={props.colorCircleFill ?? '#79EEEB'}
      />
      <path
        d="M33.736 23.033L19.23 15.78a1.083 1.083 0 00-1.565.967v14.506a1.082 1.082 0 001.565.967l14.505-7.253a1.081 1.081 0 000-1.934z"
        fill={props.colorFill ?? '#fff'}
      />
    </svg>
  );
};

export default Play;
