import { LocaleContext } from 'hooks';
import { useLocalStorage } from 'react-use';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import fr from 'locales/fr.json';
import en from 'locales/en.json';

const navigatorLanguage = window.navigator.language.split('-')[0];

const LocaleProvider = ({ children }) => {
  const [locale, setLocale] = useLocalStorage('locale', navigatorLanguage, {
    raw: true,
  });

  const handleUpdateLocale = locale => {
    setLocale(locale);
  };

  const localeOptions = [
    { label: 'Français', value: 'fr' },
    { label: 'Anglais', value: 'en' },
  ];

  i18n.use(initReactI18next).init({
    fallbackLng: 'fr',
    lng: locale,
    resources: {
      fr: fr,
      en: en,
    },
    interpolation: {
      escapeValue: false,
    },
  });

  return (
    <LocaleContext.Provider
      value={{ locale, onUpdateLocale: handleUpdateLocale, localeOptions }}
    >
      {children}
    </LocaleContext.Provider>
  );
};

export default LocaleProvider;
