import { Stack, Container as MgtContainer } from '@tymate/margaret';
import styled from 'styled-components';
import background from '../images/logo-background.svg';
import { Header } from 'components';

const Shape = styled.div`
  z-index: auto;
  position: fixed;
  mix-blend-mode: overlay;
  opacity: 38%;
`;

const Main = styled(Stack).attrs({
  direction: 'column',
})`
  overflow-y: auto;
  background: ${({ theme }) => theme.backgroundGradient};
  min-height: 100vh;
`;

const Wrapper = styled(Stack).attrs({
  direction: 'column',
  alignX: 'center',
})`
  color: #fff;
`;

const Container = styled(MgtContainer)`
  padding: ${({ theme }) => theme.spacing(2)};
`;

const Layout = ({ children, size, variant }) => (
  <Main>
    <Header />
    <Wrapper>
      {variant !== 'withoutLogo' && (
        <Shape>
          <img src={background} alt={'logo'} />
        </Shape>
      )}
      <Container size={size}>{children}</Container>
    </Wrapper>
  </Main>
);

export default Layout;
