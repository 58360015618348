import { isEmpty, pickBy, padStart, trimEnd } from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';
import qs from 'qs';
import { camelize } from 'humps';
import { format, formatRelative } from 'date-fns';
import { fr, enUS } from 'date-fns/locale';

export const useSearchParams = () => {
  const location = useLocation();
  const navigate = useNavigate();
  let searchParams = {};

  const setSearchParams = params => {
    const toLocation =
      location.pathname +
      (!isEmpty(pickBy(params))
        ? `?${qs.stringify(pickBy(params), { arrayFormat: 'indices' })}`
        : '');
    navigate(toLocation);
  };

  try {
    searchParams = qs.parse(location.search.replace('?', ''));
  } catch (err) {}

  return [searchParams, setSearchParams];
};

export const formatRestError = error => {
  const data = error?.response?.data?.error;
  if (Boolean(data?.attribute)) {
    if (Array.isArray(data?.attribute)) {
      return camelize(data?.attribute[0]).replace('_', '');
    } else {
      return camelize(data?.attribute).replace('_', '');
    }
  } else if (Boolean(data?.messages)) {
    return camelize(data?.messages).replace("'", '');
  } else {
    return camelize(data).replace("'", '');
  }
};

export const formatDurationEmbed = duration => {
  const minutes = duration > 60 ? Math.floor((duration / 60) % 60) + ':' : '0:';
  const hours =
    duration >= 60 * 60 ? Math.floor(duration / (60 * 60)) + ':' : '';

  const seconds =
    duration > 60
      ? Math.round(
          duration -
            (Math.floor((duration / 60) % 60) * 60 +
              Math.floor(duration / (60 * 60)) * 60 * 60),
        )
      : Math.round(duration);

  return `${hours}${minutes}${padStart(seconds, 2, '0')}`;
};

export const formatDuration = (input, { format, withSeconds }) => {
  const minutes =
    format === 'chapters'
      ? Math.ceil((input / 60) % 60) + ((input / 60) % 60 < 0 ? '' : 'MIN')
      : (input / 60) % 60 <= 1
      ? ''
      : padStart(Math.floor((input / 60) % 60), 2) + ' min ';
  const hours =
    input >= 60 * 60
      ? padStart(Math.floor(input / (60 * 60)), 2) +
        (format === 'chapters' ? 'H' : ' h ')
      : '';

  const seconds = withSeconds
    ? input -
      (Math.floor((input / 60) % 60) * 60 +
        Math.floor(input / (60 * 60)) * 60 * 60) +
      ' sec'
    : '';

  return trimEnd(`${hours}${minutes}${seconds}`);
};

export const formatShortDuration = (input, { format }) => {
  const minutes =
    format === 'chapters'
      ? Math.ceil((input / 60) % 60) + ((input / 60) % 60 < 0 ? '' : 'MIN')
      : (input / 60) % 60 <= 1
      ? ''
      : padStart(Math.floor((input / 60) % 60), 2) + ' min ';
  const hours =
    input >= 60 * 60
      ? padStart(Math.floor(input / (60 * 60)), 2) +
        (format === 'chapters' ? 'H' : ' h ')
      : '';

  return trimEnd(`${hours}:${minutes}`);
};

export const formatPosition = (input, options) => {
  const unity = options?.unity || 'millis';
  if (unity === 'seconds') {
    input = 1000 * input;
  }
  if (!Boolean(input) || isNaN(input)) {
    return '';
  }

  const seconds = padStart(Math.floor((input / 1000) % 60), 2, '0');
  const minutes = padStart(Math.floor((input / (1000 * 60)) % 60), 2, '0');
  const hours =
    input >= 1000 * 60 * 60
      ? padStart(Math.floor(input / (1000 * 60 * 60)), 2, '0') + ':'
      : '';

  return `${hours}${minutes}:${seconds}`;
};

export const formatDate = (input, locale) => {
  let currentLocale = fr;
  switch (locale) {
    case 'en':
      currentLocale = enUS;
      break;
    default:
      currentLocale = fr;
      break;
  }
  const formatRelativeLocale = {
    lastWeek: "eeee 'dernier'",
    yesterday: 'Hier',
    today: "Aujourd'hui",
    tomorrow: 'Demain',
    nextWeek: 'eeee',
    other: "'le' PPP",
  };

  return formatRelative(new Date(input), new Date(), {
    locale: currentLocale,
    formatRelative: token => formatRelativeLocale[token],
  });
};

export const formatDateToMonth = (input, locale) => {
  let currentLocale = fr;
  switch (locale) {
    case 'en':
      currentLocale = enUS;
      break;
    default:
      currentLocale = fr;
      break;
  }

  return format(new Date(input), 'MMM yyyy', { locale: currentLocale });
};

export const generateIframe = id => {
  const link = `https://natifpodcast.tymate.com/embed/${id}`;
  return `
    <iframe
      src=“${link}”
      width=“600"
      height=“315”
      frameborder=“0"
      allowtransparency=“true”
    />
 `;
};
