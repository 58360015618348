import React, { useEffect } from 'react';
import { AppContext } from 'hooks';
import { gql, useQuery } from '@apollo/client';
import { useAuth } from 'hooks';

const GET_CURRENT_USER = gql`
  query currentUser {
    currentUser {
      id
      bio
      createdAt
      email
      firstName
      id
      job
      lastName
      role
      avatar {
        id
        url
      }
      area {
        displayName
        id
      }
      department {
        displayName
        id
      }
      service {
        title
        id
      }
    }
  }
`;

const GET_CURRENT_ORGANIZATION = gql`
  query currentOrganization {
    currentOrganization {
      color
      displayName
      id
      kind
      logo(size: medium_section) {
        id
        url
      }
      areas {
        id
        displayName
      }
      services {
        title
        id
      }
      departments {
        displayName
        id
      }
      banner(size: onboarding_screen) {
        url
      }
      actualities(size: medium_section) {
        id
        url
      }
      welcomeDesk(size: medium_section) {
        id
        url
      }
      team(size: medium_section) {
        id
        url
      }
    }
  }
`;

const AppProvider = ({ children }) => {
  const { accessToken } = useAuth();

  const { data: userData, refetch: refetchUser } = useQuery(GET_CURRENT_USER, {
    skip: !Boolean(accessToken),
    fetchPolicy: 'network-only',
  });
  const user = userData?.currentUser ?? null;

  const { data: organizationData, refetch: refetchOrganization } = useQuery(
    GET_CURRENT_ORGANIZATION,
    {
      skip: !Boolean(accessToken),
      fetchPolicy: 'network-only',
    },
  );
  const organization = organizationData?.currentOrganization ?? null;

  useEffect(() => {
    refetchUser();
    refetchOrganization();
  }, [accessToken, refetchUser, refetchOrganization]);

  return (
    <AppContext.Provider
      value={{
        user,
        refetchUser,
        organization,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider;
