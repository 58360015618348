import { gql } from '@apollo/client';

export const GET_PODCAST = gql`
  query podcast($podcastId: ID!) {
    podcast(id: $podcastId) {
      id
      kind
      longDescription
      targeted
      areas {
        edges {
          node {
            id
            displayName
          }
        }
      }
      departments {
        edges {
          node {
            id
            displayName
          }
        }
      }
      services {
        edges {
          node {
            id
            title
          }
        }
      }
      lastListening {
        audible {
          ... on Audio {
            id
            description
            title
            hlsUrl
            duration
            createdAt
            publishedAt
            podcast {
              id
              longDescription
              title
              category {
                id
                slug
              }
              image(size: medium_section) {
                id
                url
              }
            }
            listening {
              id
            }
          }
        }
      }
      title
      image(size: medium_section) {
        id
        url
      }
      aggregateDurationTime
      audiosCount
      audios(first: 25, order: { column: PUBLISHED_AT, direction: DESC }) {
        edges {
          node {
            id
            duration
            title
            hlsUrl
            createdAt
            publishedAt
            description
            cover(size: medium_section) {
              id
              url
            }
            listening {
              id
            }
            author {
              ... on User {
                id
                firstName
                lastName
                bio
                job
                role
                discardedAt
                area {
                  id
                  displayName
                }
                service {
                  id
                  title
                }
                avatar(size: medium_section) {
                  id
                  url
                }
              }
              ... on External {
                id
                firstName
                lastName
                bio
                job
                avatar(size: medium_section) {
                  id
                  url
                }
              }
            }
            podcast {
              id
              longDescription
              title
              category {
                id
                slug
              }
              image(size: medium_section) {
                id
                url
              }
            }
            contributors {
              edges {
                node {
                  id
                  contributorable {
                    ... on User {
                      id
                      firstName
                      lastName
                      bio
                      job
                      role
                      discardedAt
                      area {
                        id
                        displayName
                      }
                      service {
                        id
                        title
                      }
                      avatar(size: medium_section) {
                        id
                        url
                      }
                    }
                    ... on External {
                      id
                      firstName
                      lastName
                      bio
                      job
                      avatar(size: medium_section) {
                        id
                        url
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      category {
        id
        slug
      }
    }
  }
`;

export const GET_LISTENING = gql`
  query listening($listeningId: ID!) {
    listening(id: $listeningId) {
      id
      listened
      duration
    }
  }
`;

export const GET_BO_PODCAST = gql`
  query boPodcast($podcastId: ID!) {
    podcast(id: $podcastId) {
      id
      kind
      longDescription
      targeted
      title
      image(size: medium_section) {
        id
        url
      }
      aggregateDurationTime
      audiosCount
      audios(
        first: 25
        order: { column: PUBLISHED_AT, direction: DESC }
        appContext: "bo"
      ) {
        edges {
          node {
            id
            duration
            title
            hlsUrl
            createdAt
            publishedAt
            draft
            description
            cover(size: medium_section) {
              id
              url
            }
            listening {
              id
            }
            author {
              ... on User {
                id
                firstName
                lastName
                bio
                job
                role
                discardedAt
                area {
                  id
                  displayName
                }
                service {
                  id
                  title
                }
                avatar(size: medium_section) {
                  id
                  url
                }
              }
              ... on External {
                id
                firstName
                lastName
                bio
                job
                avatar(size: medium_section) {
                  id
                  url
                }
              }
            }
            podcast {
              id
              longDescription
              title
              category {
                id
                slug
              }
              image(size: medium_section) {
                id
                url
              }
            }
          }
        }
      }
      category {
        id
        slug
      }
    }
  }
`;
