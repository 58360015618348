import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { useAppBanner, useAuth } from 'hooks';
import EmbedPlayerAudio from 'components/EmbedPlayerAudio';
import { GET_AUDIO_EMBED } from 'api/audio';
import { useEffect } from 'react';

const CenterBlock = styled.div`
  width: 100vw;
  height: 100vh;
  display: grid;
  place-content: center;
`;

const Embed = () => {
  const { audioId } = useParams();
  const { accessToken } = useAuth();
  const { handleDismissAppBanner } = useAppBanner();

  useEffect(() => {
    handleDismissAppBanner();
  }, [handleDismissAppBanner]);

  const { data } = useQuery(GET_AUDIO_EMBED, {
    variables: { audioId: audioId },
  });

  const audio = data?.audio;

  const track = {
    podcastTitle: audio?.podcast?.title,
    title: audio?.title,
    audioSrc: audio?.hlsUrl ?? '',
    image: audio?.cover?.url ?? audio?.podcast?.image?.url,
    duration: audio?.duration,
  };

  return (
    <CenterBlock>
      <EmbedPlayerAudio
        track={track}
        isConnected={Boolean(accessToken) || audio?.podcast?.kind === 'public'}
        audioId={audioId}
      />
    </CenterBlock>
  );
};

export default Embed;
