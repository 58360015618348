const Pause = props => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ ...props.style, fontSize: props.size }}
      {...props}
    >
      <circle
        cx={24}
        cy={24}
        r={24}
        fill={props.colorCircleFill ?? '#79EEEB'}
      />
      <path
        d="M16.5 17.25A2.25 2.25 0 0118.75 15h1.5a2.25 2.25 0 012.25 2.25v13.5A2.25 2.25 0 0120.25 33h-1.5a2.25 2.25 0 01-2.25-2.25v-13.5zM25.5 17.25A2.25 2.25 0 0127.75 15h1.5a2.25 2.25 0 012.25 2.25v13.5A2.25 2.25 0 0129.25 33h-1.5a2.25 2.25 0 01-2.25-2.25v-13.5z"
        fill={props.colorFill ?? '#fff'}
      />
    </svg>
  );
};

export default Pause;
