import { get, post } from './base';
import { CLIENT_ID } from '../constants';

export const login = ({ email, password }) =>
  post(`api/v1/oauth/token`, {
    username: email,
    password,
    clientId: CLIENT_ID,
    grantType: 'password',
  });

export const loginProvider = (provider, code, redirectUri, codeVerifier) =>
  post(`api/v1/oauth/token`, {
    provider: provider,
    assertion: code,
    clientId: CLIENT_ID,
    grantType: 'assertion',
    redirectUri: redirectUri,
    codeVerifier: codeVerifier,
  });

export const refreshToken = refreshToken =>
  post(`api/v1/oauth/token`, {
    refreshToken,
    clientId: CLIENT_ID,
    grantType: 'refresh_token',
  });

export const firstLogin = ({ email, locale = 'fr' }) =>
  post(`v1/users/first_login`, {
    email,
    locale,
  });

export const settingPassword = ({ accessToken, password }) =>
  post(`/v1/users/${accessToken}/activate_account`, {
    password,
    clientId: CLIENT_ID,
  });

export const forgotPassword = ({ email, locale = 'fr' }) =>
  post(`v1/users/forgot_password`, {
    email,
    locale,
  });

export const resetPassword = ({ accessToken, password }) =>
  post(`/v1/users/${accessToken}/reset_password`, {
    password,
    clientId: CLIENT_ID,
  });

export const signInCryptr = accessToken =>
  post(`api/v1/oauth/token`, {
    provider: 'cryptr',
    clientId: CLIENT_ID,
    grantType: 'assertion',
    accessToken: accessToken,
  });

export const checkEmail = email =>
  get('/v1/users/check_email', { params: { email } });
