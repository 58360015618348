import { Stack, Text } from '@tymate/margaret';
import { useField } from 'formik';
import { MdCheckBoxOutlineBlank, MdCheckBox } from 'react-icons/md';
import { FormField, FormLabel } from 'ui/forms';
import styled from 'styled-components';

const Tick = styled.div`
  width: 2rem;
  height: 2rem;
  margin-left: 0;
`;

const CheckboxField = ({ name, value, label, lineLabel, ...props }) => {
  const [field] = useField({ name, type: 'checkbox' });
  return (
    <FormField>
      <FormLabel disabled={props.disabled}>
        <Stack direction="row" gutterSize={1}>
          <input
            {...field}
            {...props}
            type="checkbox"
            style={{ display: 'none' }}
          />
          <Tick>
            {field.checked ? (
              <MdCheckBox style={{ width: '1.5rem', height: '1.5rem' }} />
            ) : (
              <MdCheckBoxOutlineBlank
                style={{ width: '1.5rem', height: '1.5rem' }}
              />
            )}
          </Tick>
          <Text>
            {label}{' '}
            {lineLabel && (
              <span style={{ textDecoration: 'underline' }}>{lineLabel}</span>
            )}
          </Text>
        </Stack>
      </FormLabel>
    </FormField>
  );
};

export default CheckboxField;
