import styled from 'styled-components';
import { useRef, useState } from 'react';
import { Text, ButtonReset, Dropdown } from '@tymate/margaret';
import { Stack } from '@tymate/margaret';
import Hls from 'hls.js';
import { useDeepCompareEffect } from 'react-use';
import {
  IcPlayBack,
  IcPlayForward,
  IcPlay,
  IcPause,
  IcSoundDecrease,
  IcSoundIncrease,
  IcShare,
} from 'components/Icons';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { formatDurationEmbed, generateIframe } from 'utils';
import { PopoverMenu, PopoverItemButton } from 'ui';
import { useTranslation } from 'react-i18next';

const Image = styled.img`
  min-width: 144px;
  width: 144px;
  height: 144px;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.backgroundLight};
  object-fit: cover;
`;

const PlayerWrapper = styled(Stack).attrs({
  direction: 'column',
  gap: 0.5,
})`
  max-width: 390px;
  width: 100%;
  border-radius: 12px;
  z-index: 1;
  background: ${({ theme }) => theme.backgroundPlayer};
  padding: ${({ theme }) => theme.spacing(1.5)}
    ${({ theme }) => theme.spacing(2)};
  border: 1px solid ${({ theme }) => theme.playerBorder};
  color: var(--white);
`;

const ControlButton = styled(ButtonReset)`
  font-size: 24px;
  color: var(--white);

  &:hover {
    color: var(--white);
  }
`;

const TextCode = styled(Text)`
  padding: ${({ theme }) => theme.spacing(1)};
  white-space: pre-line;
  background-color: ${({ theme }) => theme.backgroundTextCode};
  border: 1px solid ${({ theme }) => theme.separator};
  flex: 1;
  border-radius: 8px;
  min-height: 40px;
  min-width: 327px;
  width: 100%;
  font-family: Menlo;
`;

const PlayerAudio = ({
  selectedAudio,
  imagePodcast,
  onAudioIsEnded,
  audioId,
}) => {
  const audioRef = useRef();
  const intervalRef = useRef();
  const isReady = useRef(false);
  const { t } = useTranslation('button');

  const [isPlaying, setIsPlaying] = useState(false);
  const [audioProgress, setTrackProgress] = useState(0);
  const [volume, setVolume] = useState(100);
  const [iframe, setIframe] = useState('');

  const [firstVisit, setFirstVisit] = useState(
    localStorage.getItem('firstVisit') ?? true,
  );

  const duration = audioRef?.current?.duration;

  const startTimer = () => {
    // Clear any timers already running
    clearInterval(intervalRef.current);

    intervalRef.current = setInterval(() => {
      setTrackProgress(audioRef.current.currentTime);
    }, [1000]);
  };

  const handleScrub = value => {
    // Clear any timers already running
    clearInterval(intervalRef.current);
    audioRef.current.currentTime = value;
    setTrackProgress(audioRef.current.currentTime);
  };

  const handleScrubEnd = () => {
    // If not already playing, start
    if (!isPlaying) {
      setIsPlaying(true);
    }
    startTimer();
  };

  const handleForward = () => {
    clearInterval(intervalRef.current);
    audioRef.current.currentTime = audioRef.current.currentTime + 30;
    setTrackProgress(audioRef.current.currentTime);

    handleScrubEnd();
  };

  const handleRewind = () => {
    clearInterval(intervalRef.current);
    audioRef.current.currentTime = audioRef.current.currentTime - 15;
    setTrackProgress(audioRef.current.currentTime);

    handleScrubEnd();
  };

  const handleVolume = value => {
    audioRef.current.volume = value / 100;
    setVolume(value);
  };

  const handleShare = () => {
    const model = generateIframe(audioId);
    setIframe(model);
  };

  const handleCopyClipboard = () => {
    navigator.clipboard.writeText(iframe);
  };

  useDeepCompareEffect(() => {
    if (!Boolean(selectedAudio)) {
      return;
    }
    if (Boolean(audioRef?.current)) {
      audioRef.current.pause();
    }

    clearInterval(intervalRef.current);
    audioRef.current = new Audio(selectedAudio?.hlsUrl);
    if (Hls.isSupported()) {
      const hls = new Hls();
      hls.loadSource(selectedAudio?.hlsUrl);
      hls.attachMedia(audioRef.current);
    }

    setTrackProgress(audioRef.current.currentTime);
    isReady.current = true;
  }, [{ selectedAudio }]);

  useDeepCompareEffect(() => {
    if (!Boolean(audioRef.current)) {
      return;
    }

    if (isPlaying) {
      audioRef.current.play();
      startTimer();
    } else {
      audioRef.current.pause();
    }
  }, [{ isPlaying }]);

  useDeepCompareEffect(() => {
    if (audioProgress === duration) {
      setIsPlaying(false);
      onAudioIsEnded();
    }
  }, [{ audioProgress }]);

  useDeepCompareEffect(async () => {
    if (isPlaying) {
      await setIsPlaying(false);
    }
    setTrackProgress(0);
    setVolume(volume);

    if (firstVisit) {
      setFirstVisit(localStorage.setItem('firstVisit', false));
    } else {
      setIsPlaying(true);
    }
  }, [selectedAudio]);

  return (
    <PlayerWrapper>
      <Stack alignX="flex-end" style={{ zIndex: 1, position: 'relative' }}>
        <Dropdown
          trigger={
            <ButtonReset onClick={handleShare}>
              <IcShare />
            </ButtonReset>
          }
        >
          <PopoverMenu alignment={'right'} style={{ width: '350px' }}>
            <Stack size="full" direction="column">
              <Stack style={{ margin: '10px' }}>
                <TextCode>{iframe}</TextCode>
              </Stack>
              <PopoverItemButton onClick={handleCopyClipboard}>
                {t('copy')}
              </PopoverItemButton>
            </Stack>
          </PopoverMenu>
        </Dropdown>
      </Stack>
      <Stack alignX="center" paddingBottom={0.5}>
        <Image src={imagePodcast} alt={selectedAudio?.title ?? ''} />
      </Stack>
      <Stack alignY="flex-end" size="full" gap={0.5} direction="column">
        <Slider
          value={audioProgress}
          step={1}
          min={0}
          max={duration ? duration : `${duration}`}
          onChange={handleScrub}
          onAfterChange={handleScrubEnd}
          audioStyle={{ backgroundColor: 'var(--ui-primary)' }}
          handleStyle={{ border: 'none' }}
        />

        <Stack alignX="space-between">
          <div>
            <Text type="footnote" color="textCallout">
              {formatDurationEmbed(audioProgress)}
            </Text>
          </div>
          <div>
            <Text type="footnote" color="textCallout">
              {formatDurationEmbed(selectedAudio?.duration)}
            </Text>
          </div>
        </Stack>
      </Stack>

      <Stack
        size="full"
        alignY="center"
        alignX="center"
        direction="column"
        flex={1}
        gap={3}
      >
        <Text type="h2" color="text">
          {selectedAudio?.title}
        </Text>

        <Stack alignX="center" alignY="center" gap={3}>
          <ControlButton onClick={handleRewind}>
            <IcPlayBack colorFill="#fff" fillOpacity={1} />
          </ControlButton>
          {isPlaying ? (
            <ButtonReset onClick={() => setIsPlaying(false)} aria-label="Pause">
              <IcPause
                size={64}
                colorCircleFill="rgba(0, 170, 255, 0.16)"
                colorFill="var(--ui-primary)"
              />
            </ButtonReset>
          ) : (
            <ButtonReset onClick={() => setIsPlaying(true)} aria-label="Play">
              <IcPlay
                size={64}
                colorCircleFill="rgba(0, 170, 255, 0.16)"
                colorFill="var(--ui-primary)"
              />
            </ButtonReset>
          )}
          <ControlButton onClick={handleForward}>
            <IcPlayForward colorFill="#fff" fillOpacity={1} />
          </ControlButton>
        </Stack>
        <Stack size="full" gap={1} alignY="center">
          <div>
            <IcSoundDecrease size={32} />
          </div>
          <Slider
            defaultValue={1}
            value={volume}
            min={0}
            max={100}
            step={1}
            onChange={handleVolume}
            audioStyle={{ backgroundColor: 'var(--ui-primary)' }}
            handleStyle={{ border: 'none' }}
          />
          <div>
            <IcSoundIncrease size={32} />
          </div>
        </Stack>
      </Stack>
    </PlayerWrapper>
  );
};

export default PlayerAudio;
