import styled from 'styled-components';
import { Stack, Text } from '@tymate/margaret';
import placeholder from 'images/placeholder.png';

const Image = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 100%;
  border: 1px solid var(--ui-primary);
  box-sizing: border-box;
`;

const ProfileCard = ({ lastName, firstName, avatar }) => {
  return (
    <Stack direction="row" alignX="flex-start" alignY="center" gap={0.5}>
      <Image src={avatar ?? placeholder} />
      <Text style={{ color: 'var(--ui-primary)' }}>
        {lastName} {firstName}
      </Text>
    </Stack>
  );
};

export default ProfileCard;
