import React, { useState } from 'react';
import { formatDuration, formatPosition } from 'utils';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { GET_LISTENING } from 'api';
import { Box, Stack, Text, ButtonReset } from '@tymate/margaret';
import PlayButton from './PlayButton';
import { usePlayer } from 'hooks';
import DetailsModal from './DetailsModal';
import AudioItemProgress from './AudioItemProgress';

const ChapterItem = ({ audio, isTheLastAudio }) => {
  const { t } = useTranslation('podcastDetail');
  const {
    setTrack,
    setCurrentAudio,
    currentAudio,
    togglePlayPause,
  } = usePlayer();
  const [modalIsShown, setModalIsShown] = useState(false);

  const { data } = useQuery(GET_LISTENING, {
    variables: { listeningId: audio?.listening?.id },
  });

  const listening = data?.listening;
  const audioChanged = currentAudio !== audio?.id;

  const audioInfo = {
    podcastTitle: audio?.podcast?.title,
    title: audio?.title,
    audioSrc: audio?.hlsUrl ?? '',
    image: audio?.cover?.url ?? audio?.podcast?.image?.url,
    duration: audio?.duration,
    listeningDuration: listening?.duration,
  };

  const handlePlayButton = () => {
    setTrack(audioInfo);
    setCurrentAudio(audio?.id);
    togglePlayPause();
  };

  return (
    <Stack gap={0.5}>
      <Stack direction="column" alignX="center">
        <PlayButton
          onClick={handlePlayButton}
          idChanged={audioChanged}
          size={40}
          replay={Boolean(listening?.listened)}
        />

        {!isTheLastAudio && (
          <Box
            style={{
              backgroundColor: 'rgba(0, 170, 255, 0.16)',
              width: 4,
              flex: 1,
            }}
          />
        )}
      </Stack>

      <Stack direction="column" size="full" gap={0.5} paddingBottom={2}>
        <Text>{audio?.title}</Text>

        <div
          marginTop={0.5}
          style={{
            color: 'rgba(255, 255, 255, 0.38)',
            fontSize: '14px',
            overflow: 'hidden',
            maxHeight: 40,
            textOverflow: 'ellipsis',
          }}
          dangerouslySetInnerHTML={{ __html: audio?.description }}
        />
        <Stack alignY="center" size="full" gap={0.5}>
          {(!Boolean(listening) || Boolean(listening?.listened)) && (
            <Text
              type="callout"
              color="textLight"
              style={{ whiteSpace: 'nowrap' }}
            >
              {formatDuration(audio?.duration, { withSeconds: true })}
            </Text>
          )}
          {Boolean(listening) && (
            <Stack alignY="center" size="full" flexGrow={1} gap={0.5}>
              <AudioItemProgress key={audio.id} audio={audio} />
              {!Boolean(listening?.listened) && (
                <Text
                  type="callout"
                  color="textLight"
                  style={{ whiteSpace: 'nowrap' }}
                >
                  {' '}
                  {formatPosition(Math.round(listening?.duration * 1000))}/
                  {formatPosition(Math.round(audio?.duration * 1000))}
                </Text>
              )}
            </Stack>
          )}
          <Box flexGrow={1} />

          <Text
            as={ButtonReset}
            onClick={() => setModalIsShown(true)}
            color="primary"
            style={{ whiteSpace: 'nowrap' }}
          >
            {t('detail')}
          </Text>
        </Stack>
      </Stack>

      <DetailsModal
        isOpen={modalIsShown}
        onRequestClose={() => setModalIsShown(false)}
        audio={audio}
        isChapter
      />
    </Stack>
  );
};

export default ChapterItem;
