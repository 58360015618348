import React from 'react';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  from,
  Observable,
} from '@apollo/client';
import { API_BASE } from '../constants';
import { useAuth } from 'hooks';
import { onError } from 'apollo-link-error';
import { setContext } from '@apollo/client/link/context';
import { createUploadLink } from 'apollo-upload-client';
import { includes } from 'lodash';
import { refreshToken } from 'api';

export const promiseToObservable = promise =>
  new Observable(subscriber => {
    promise.then(
      value => {
        if (subscriber.closed) {
          return;
        }
        subscriber.next(value);
        subscriber.complete();
      },
      err => {
        subscriber.error(err);
      },
    );
    return subscriber;
  });

const GraphQLProvider = ({ children }) => {
  const {
    accessToken,
    onUpdate,
    onReset,
    refreshToken: refreshTokenValue,
  } = useAuth();

  const uploadLink = createUploadLink({
    uri: `${API_BASE}/graphql`,
  });

  const authLink = setContext((_, { headers }) => ({
    headers: {
      ...headers,
      authorization: Boolean(accessToken) ? `Bearer ${accessToken}` : '',
    },
  }));

  const refreshTokenLink = onError(({ graphQLErrors, forward, operation }) => {
    if (!Boolean(refreshTokenValue)) {
      return;
    }
    if (
      includes(
        (graphQLErrors || []).map(
          graphQLError => graphQLError?.extensions?.code,
        ),
        'unauthorized',
      )
    ) {
      return promiseToObservable(refreshToken(refreshTokenValue)).flatMap(
        ({ data }) => {
          const { accessToken, refreshToken } = data;
          onUpdate({ accessToken, refreshToken });
          operation.setContext((_, { headers }) => ({
            headers: {
              ...headers,
              authorization: Boolean(accessToken)
                ? `Bearer ${accessToken}`
                : '',
            },
          }));
          return forward(operation);
        },
        () => {
          onReset();
          return forward(operation);
        },
      );
    }

    return forward(operation);
  });

  const client = new ApolloClient({
    link: from([authLink, refreshTokenLink, uploadLink]),
    cache: new InMemoryCache(),
    defaultOptions: {
      query: {
        fetchPolicy: 'cache-and-network',
        errorPolicy: 'all',
      },
      watchQuery: {
        fetchPolicy: 'cache-and-network',
        errorPolicy: 'all',
      },
    },
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default GraphQLProvider;
