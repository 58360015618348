import { Stack, Text } from '@tymate/margaret';
import { Button, Layout } from 'components';
import { useTranslation } from 'react-i18next';

const NotFound = () => {
  const { t } = useTranslation(['login', 'errors']);

  return (
    <Layout size="tiny">
      <Stack alignX="center" direction="column" gutterSize={2} size="full">
        <div>
          <Text type="h1" style={{ textAlign: 'center' }}>
            {t('notFoundTitle')}
          </Text>
        </div>
        <div>
          <Text
            type="body"
            fontWeight="bold"
            style={{
              textAlign: 'center',
              opacity: 0.6,
            }}
          >
            {t('notFoundDesc')}
          </Text>
        </div>
        <Button type="button" variant="outline" to="/login">
          {t('button:back')}
        </Button>
      </Stack>
    </Layout>
  );
};

export default NotFound;
