import { useState } from 'react';
import { Stack, Text, ButtonReset, useMargaret } from '@tymate/margaret';
import { Layout } from 'components';
import { useQuery } from '@apollo/client';
import AudioPlayer from 'components/Audio/AudioPlayer';
import AudioCard from 'components/Audio/AudioCard';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useAuth } from 'hooks';
import { Navigate, useParams } from 'react-router';
import ProfileCard from 'components/ProfilCard';
import { uniqBy } from 'lodash';
import NotFound from 'containers/NotFound';
import Truncate from 'react-truncate';
import { GET_AUDIO } from 'api/audio';
import { useDeepCompareEffect } from 'react-use';

// const GradientBack = styled.div`
//   position: absolute;
//   bottom: 0px;
//   left: 0px;
//   width: 100%;
//   height: 50px;
//   background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
//   z-index: 1;
// `;
//TODO : A voir le nombre d'items (5) et la Gradient ce fix a la liste

const Image = styled.img`
  width: 144px;
  height: 144px;
  border-radius: 12px;
  object-fit: cover;
`;

const InfoWrapper = styled(Stack).attrs({
  direction: 'column',
  gap: 0.5,
})`
  ${({ theme }) => theme.media.tablet`
      max-width: 400px;
  `};
`;

const WrapperListAudio = styled(Stack).attrs({
  gap: 1,
  direction: 'column',
})`
  overflow-y: scroll;
  max-height: 500px;
  z-index: 2;
  position: relative;
  -ms-overflow-style: none;
  scrollbar-width: none;

  ::-webkit-scrollbar {
    display: none;
  }
`;

const WrapperAudio = styled(Stack).attrs({
  gap: 0.5,
  direction: 'column',
  alignY: 'flex-start',
})``;

const Anchor = styled.a`
  color: #fff;
`;

const Pill = styled(Stack).attrs({
  alignX: 'center',
  alignY: 'center',
})`
  border-radius: 8px;
  padding: ${({ theme }) => theme.spacing(0)}
    ${({ theme }) => theme.spacing(0.5)};
  background: ${({ theme }) => theme.backgroundTag};
`;

const Podcast = () => {
  const { audioId } = useParams();
  const { t } = useTranslation('podcast');
  const { accessToken } = useAuth();
  const { isMobile } = useMargaret();
  const [selectedAudio, setSelectedAudio] = useState(0);
  const [isTruncated, setIsTruncated] = useState(true);
  const [isExpanded, setExpanded] = useState(!isMobile);

  const { data, loading } = useQuery(GET_AUDIO, {
    variables: { audioId: audioId },
  });

  const audio = data?.audio;

  useDeepCompareEffect(() => {
    if (Boolean(audio?.position)) {
      setSelectedAudio(audio?.position - 1);
    }
  }, [audio?.position]);

  if (!Boolean(accessToken)) {
    return <Navigate to="/login" />;
  }

  if (!Boolean(audioId)) {
    return <NotFound />;
  }

  if (loading) {
    return <Layout>chargement ...</Layout>;
  }

  if (!Boolean(data?.audio)) {
    return <NotFound />;
  }

  const authors = uniqBy(
    (audio?.podcast?.authors.nodes ?? []).map(author => author) || [],
    'id',
  );

  const tags = (audio?.podcast.category?.tags?.edges ?? []).map(
    tag => tag?.node,
  );

  const lines = 3;

  const handleAudioIsEnded = () => {
    if (selectedAudio < audio?.podcast?.audios?.edges?.length - 1) {
      setSelectedAudio(selectedAudio + 1);
    }
  };

  const handleTruncate = truncated => {
    if (truncated !== isTruncated) {
      setIsTruncated(truncated);
    }
  };

  const toggleLines = () => {
    setExpanded(!isExpanded);
  };

  return (
    <Layout variant="withoutLogo" size="medium">
      <Stack direction="column" alignX="center">
        <Stack direction="column" gap={1} style={{ width: '100%' }}>
          <InfoWrapper>
            <Image src={audio?.podcast.image?.url} />
            <div>
              <Text type="h1">{audio?.podcast?.title}</Text>
            </div>
            <Truncate
              onTruncate={handleTruncate}
              lines={!isExpanded && lines}
              ellipsis={
                <span>
                  ...
                  <Anchor href="#" onClick={toggleLines}>
                    {t('more')}
                  </Anchor>
                </span>
              }
            >
              <Text type="body" color="textCallout">
                {audio?.podcast?.longDescription}
              </Text>
            </Truncate>
            {!isTruncated && isMobile && (
              <span>
                <Anchor href="#" onClick={toggleLines}>
                  {t('less')}
                </Anchor>
              </span>
            )}
            <Stack gap={1}>
              {(tags ?? []).map(({ name, id }) => (
                <Pill key={id}>
                  <Text
                    type="callout"
                    color="primary"
                    style={{ lineHeight: 1 }}
                  >
                    {name}
                  </Text>
                </Pill>
              ))}
            </Stack>
          </InfoWrapper>

          {authors.length > 0 && (
            <Stack alignX="flex-start" direction="column" gap={0.75}>
              <Text type="body">{t('author')}</Text>
              {authors?.map(({ lastName, firstName, avatar, id }) => (
                <ProfileCard
                  key={id}
                  lastName={lastName}
                  firstName={firstName}
                  avatar={avatar}
                />
              ))}
            </Stack>
          )}

          <Stack
            gap={2}
            alignY="flex-start"
            alignX={{
              default: 'center',
              tablet: 'flex-start',
            }}
            direction={{
              default: 'column',
              tablet: 'row',
            }}
          >
            <AudioPlayer
              imagePodcast={audio?.podcast?.image?.url}
              selectedAudio={
                audio?.podcast?.audios?.edges?.[selectedAudio]?.node
              }
              audioId={audio?.podcast?.audios?.edges?.[selectedAudio]?.node?.id}
              onAudioIsEnded={handleAudioIsEnded}
            />

            <WrapperAudio>
              <Text type="h3">{t('chapters')}</Text>
              <WrapperListAudio>
                {audio?.podcast?.audios?.edges.map(({ node }, index) => (
                  <ButtonReset
                    key={index}
                    onClick={() => {
                      setSelectedAudio(index);
                    }}
                  >
                    <AudioCard
                      audio={node}
                      imagePodcast={audio?.podcast?.image?.url}
                      isActive={index === selectedAudio}
                    />
                  </ButtonReset>
                ))}
              </WrapperListAudio>
            </WrapperAudio>
          </Stack>
        </Stack>
      </Stack>
    </Layout>
  );
};

export default Podcast;
