import { memo } from 'react';

const IcPower = props => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    style={{ ...props.style, fontSize: props.size }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 .748a.75.75 0 01.75.75v9.75a.75.75 0 01-1.5 0v-9.75a.75.75 0 01.75-.75z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.707 4.761a.75.75 0 01-.457.957 8.25 8.25 0 105.5 0 .75.75 0 11.5-1.414 9.75 9.75 0 11-6.5 0 .75.75 0 01.957.457z"
      fill="currentColor"
    />
  </svg>
);

export default memo(IcPower);
