import { useTranslation } from 'react-i18next';
import Button from './Button';
import { useNavigate } from 'react-router-dom';
import ArrowLeft from './Icons/ArrowLeft';
import { Text } from '@tymate/margaret';

const AdminBackButton = () => {
  const { t } = useTranslation('misc');
  const navigate = useNavigate();

  return (
    <Button variant="text" icon={<ArrowLeft />} onClick={() => navigate(-1)}>
      <Text color="textDark">{t('back')}</Text>
    </Button>
  );
};

export default AdminBackButton;
