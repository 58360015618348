import styled, { css, keyframes } from 'styled-components';
import { useRef, useState } from 'react';
import { Text, ButtonReset, useMargaret } from '@tymate/margaret';
import { Button } from 'components';
import { Stack } from '@tymate/margaret';
import logo from 'images/logo-embed.svg';
import { useDeepCompareEffect } from 'react-use';
import { IcPlayBack, IcPlayForward, IcPlay, IcPause } from 'components/Icons';
import { useTranslation } from 'react-i18next';
import natifLogo from 'images/logo-red.png';
import placeholder from 'images/placeholder.png';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { formatDurationEmbed } from 'utils';

const scrollingLeft = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
`;

const Image = styled.img`
  min-width: 60px;
  width: 60px;
  height: 60px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  background-color: ${({ theme }) => theme.backgroundLight};
  object-fit: cover;

  ${({ theme }) => theme.media.embed`
  min-width: 90px;
  width: 90px;
  height: 90px;
    `};

  ${({ theme }) => theme.media.desktop`
  min-width: 112px;
  width: 112px;
  height: 112px;
    `};
`;

const Logo = styled.img`
  width: 14px;
  height: 14px;

  ${({ theme }) => theme.media.embed`
  width: 16px;
  height: 16px;
    `};

  ${({ theme }) => theme.media.desktop`
  width: 18px;
  height: 18px;
    `};
`;

const LogoNatif = styled.img`
  margin-right: 8px;
  width: 60px;

  ${({ theme }) => theme.media.embed`
    width: 76px;
    `};

  ${({ theme }) => theme.media.desktop`
    width: 92px;
    `};
`;

const EmbedWrapper = styled(Stack).attrs({
  // direction: { default: 'column', embed: 'row' },
  alignY: 'center',
  alignX: { default: 'center', embed: 'flex-start' },
  padding: { default: 1, embed: 0.5 },
  margin: 1,
  gap: 0.5,
})`
  width: 400px;
  max-width: 90vw;
  background: ${({ theme }) => theme.backgroundEmbed};
  border-radius: 12px;
  position: relative;

  ${({ theme }) => theme.media.embed`
  width: 100%;
  max-width: 480px;
    `};

  ${({ theme }) => theme.media.desktop`
  width: 100%;
  max-width: 600px;
    `};
`;

const PrimaryButton = styled(ButtonReset)`
  font-size: 36px;
  ${({ theme }) => theme.media.tablet`
  font-size: 40px;
    `};
  ${({ theme }) => theme.media.desktop`
  font-size: 48px;
    `};
`;

const ControlButton = styled(ButtonReset)`
  font-size: 20px;
  color: ${({ theme }) => theme.textLighter};
  &:hover {
    color: ${({ theme }) => theme.textLight};
  }

  ${({ theme }) => theme.media.embed`
  font-size: 22px;
    `};

  ${({ theme }) => theme.media.desktop`
  font-size: 24px;
    `};
`;

const AnimatedText = styled(Text)`
  will-change: transform;
  display: inline-block;

  ${({ isAnimated }) =>
    isAnimated &&
    css`
      animation: ${scrollingLeft} var(--time) infinite linear;
      animation-delay: 2s;
    `}
`;

const WrapperNoWrap = styled.div`
  white-space: nowrap;
  overflow: hidden;
`;

const LoginButton = styled(Button)`
  align-self: center;
  width: min-content;
  font-size: 12px;

  ${({ theme }) => theme.media.embed`
    font-size: 14px;
  `};

  ${({ theme }) => theme.media.desktop`
    font-size: 16px;
    margin-top: ${({ theme }) => theme.spacing(0.5)};
    align-self: flex-end;
  `};
`;

const EmbedPlayerAudio = ({ track, isConnected, audioId }) => {
  const { t } = useTranslation('podcast');
  const { isMobile } = useMargaret();
  const overflowTitleRef = useRef();
  const overflowSubtitleRef = useRef();
  const audioRef = useRef();
  const intervalRef = useRef();
  const isReady = useRef(false);

  const [hasOverflows, setHasOverflows] = useState({
    title: false,
    subtitle: false,
  });
  const [isPlaying, setIsPlaying] = useState(false);
  const [trackProgress, setTrackProgress] = useState(0);

  const duration = audioRef?.current?.duration;

  const podcastTitle =
    Boolean(track?.podcastTitle) &&
    `${track?.podcastTitle}${hasOverflows?.title ? ' •\xa0' : ''}`;
  const trackTitle =
    Boolean(track?.title) &&
    `${track?.title}${hasOverflows?.subtitle ? ' •\xa0' : ''}`;

  const startTimer = () => {
    // Clear any timers already running
    clearInterval(intervalRef.current);

    intervalRef.current = setInterval(() => {
      setTrackProgress(audioRef.current.currentTime);
    }, [1000]);
  };

  const handleScrub = value => {
    // Clear any timers already running
    clearInterval(intervalRef.current);
    audioRef.current.currentTime = value;
    setTrackProgress(audioRef.current.currentTime);
  };

  const handleScrubEnd = () => {
    // If not already playing, start
    if (!isPlaying) {
      setIsPlaying(true);
      audioRef.current.play();
      startTimer();
    }
    startTimer();
  };

  const handleForward = () => {
    clearInterval(intervalRef.current);
    const newCurrentTime = Math.min(
      audioRef.current.currentTime + 30,
      duration,
    );
    audioRef.current.currentTime = newCurrentTime;
    setTrackProgress(newCurrentTime);
    handleScrubEnd();
  };

  const handleRewind = () => {
    clearInterval(intervalRef.current);
    const newCurrentTime = Math.max(audioRef.current.currentTime - 15, 0);
    audioRef.current.currentTime = newCurrentTime;
    setTrackProgress(newCurrentTime);
    handleScrubEnd();
  };

  useDeepCompareEffect(() => {
    if (!Boolean(track)) {
      return;
    }

    audioRef.current = new Audio(track.audioSrc);
    audioRef.current.playsInline = true;
    audioRef.current.preload = 'metadata';
    audioRef.current.title = trackTitle;

    setTrackProgress(audioRef.current.currentTime);
    isReady.current = true;
  }, [{ track }]);

  useDeepCompareEffect(() => {
    if (trackProgress === duration) {
      setIsPlaying(false);
      audioRef.current.pause();
      window.fathom.trackGoal('IOEBX1VD', 0);
    }
  }, [{ trackProgress }]);

  useDeepCompareEffect(() => {
    if (track && overflowTitleRef.current && overflowSubtitleRef.current) {
      const title = overflowTitleRef.current;
      const subtitle = overflowSubtitleRef.current;
      const titleIsOverflowing = title.offsetWidth < title.scrollWidth;
      const subtitleIsOverflowing = subtitle.offsetWidth < subtitle.scrollWidth;

      setHasOverflows({
        title: titleIsOverflowing,
        subtitle: subtitleIsOverflowing,
      });
    }
  }, [{ track }]);

  return (
    <EmbedWrapper>
      <Image src={track?.image || placeholder} alt={podcastTitle || ''} />

      <Stack style={{ position: 'absolute', top: 8, right: 8 }}>
        <a href="https://natif.io" target="_blank" rel="noopener noreferrer">
          <Logo alt="logo" src={logo} />
        </a>
      </Stack>

      {isConnected ? (
        <Stack
          size="full"
          direction="column"
          alignY="space-between"
          style={{ height: '100%', overflow: 'hidden' }}
          paddingTop={isMobile ? 0.25 : 0.5}
          paddingBottom={isMobile && 0.5}
        >
          <Stack
            size="full"
            alignY="center"
            direction="column"
            style={{ overflow: 'hidden' }}
          >
            <Stack
              alignX="space-between"
              alignY="center"
              gap={0.375}
              style={{ overflow: 'hidden' }}
            >
              <Stack
                direction="column"
                gap={isMobile ? 0.125 : 0.25}
                style={{
                  overflow: 'hidden',
                }}
              >
                <WrapperNoWrap
                  ref={overflowTitleRef}
                  style={{
                    '--time': `${(podcastTitle?.length || 0) / 2}s`,
                  }}
                >
                  <AnimatedText
                    type="subtitle"
                    color="textDark"
                    fontWeight="bold"
                    isAnimated={hasOverflows.title}
                  >
                    {podcastTitle}
                  </AnimatedText>

                  {hasOverflows.title && (
                    <AnimatedText
                      type="subtitle"
                      color="textDark"
                      fontWeight="bold"
                      isAnimated
                    >
                      {podcastTitle}
                    </AnimatedText>
                  )}
                </WrapperNoWrap>
                <WrapperNoWrap
                  ref={overflowSubtitleRef}
                  style={{
                    '--time': `${(trackTitle?.length || 0) / 2}s`,
                  }}
                >
                  <AnimatedText
                    type="body"
                    color="textLightOnLightTheme"
                    isAnimated={hasOverflows.subtitle}
                  >
                    {trackTitle}
                  </AnimatedText>
                  {hasOverflows.subtitle && (
                    <AnimatedText
                      type="body"
                      color="textLightOnLightTheme"
                      isAnimated
                    >
                      {trackTitle}
                    </AnimatedText>
                  )}
                </WrapperNoWrap>
              </Stack>

              {isReady?.current && (
                <Stack gap={isMobile ? 0.5 : 0.75}>
                  <ControlButton onClick={handleRewind}>
                    <IcPlayBack />
                  </ControlButton>

                  <PrimaryButton
                    onClick={() => {
                      setIsPlaying(!isPlaying);
                      if (!isPlaying) {
                        audioRef.current.play();
                        startTimer();
                        window.fathom?.trackGoal('LIDYKTHR', 0);
                      } else {
                        audioRef.current.pause();
                      }
                    }}
                    aria-label={isPlaying ? 'Pause' : 'Play'}
                  >
                    {isPlaying ? <IcPause /> : <IcPlay />}
                  </PrimaryButton>

                  <ControlButton onClick={handleForward}>
                    <IcPlayForward />
                  </ControlButton>
                </Stack>
              )}
            </Stack>
          </Stack>
          {isReady?.current && (
            <Stack alignY="center" size="full" gap={0.5}>
              <Slider
                value={trackProgress}
                step={1}
                min={0}
                max={duration ? duration : track?.duration || 0}
                onChange={handleScrub}
                onAfterChange={handleScrubEnd}
                trackStyle={{ backgroundColor: 'var(--ui-primary)' }}
                handleStyle={{ display: 'none' }}
              />
              <div style={{ width: 'max-content' }}>
                <Text
                  type="footnote"
                  color="textLighterOnLightTheme"
                  style={{ width: 'max-content' }}
                >
                  {formatDurationEmbed(trackProgress)}
                  {' / '}
                  {formatDurationEmbed(track?.duration || 0)}
                </Text>
              </div>
            </Stack>
          )}
        </Stack>
      ) : (
        <Stack
          size="full"
          direction={{ default: 'column', embed: 'row' }}
          gap={0.5}
          marginRight={0.75}
          alignX="space-between"
        >
          <Stack direction="column" gap={0.5}>
            <Stack
              gap={0.5}
              alignY="center"
              // direction={{ default: 'column', embed: 'row' }}
            >
              <Text type="subtitle" color="textLightOnLightTheme">
                {t('listenTo')}
              </Text>
              <LogoNatif src={natifLogo} alt="Natif" />
            </Stack>
            <div>
              <Text type="body" color="textLightOnLightTheme">
                {track?.title}
              </Text>
            </div>
          </Stack>

          <Stack alignX="flex-end" alignY="center">
            <LoginButton
              variant="embed"
              onClick={() =>
                window.open(
                  `${process.env.REACT_APP_BASE_URL}/login?callback=/embed/${audioId}`,
                  '_blank',
                )
              }
            >
              {t('button:login')}
            </LoginButton>
          </Stack>
        </Stack>
      )}
    </EmbedWrapper>
  );
};

export default EmbedPlayerAudio;
