import { useEffect, useState } from 'react';
import { useField } from 'formik';
import { Stack } from '@tymate/margaret';
import { FormField, ErrorMessageWrapper, FormLabel } from 'ui/forms';
import { Avatar } from 'components';

const AvatarField = ({ label, name, url, size = 'medium', placeholder }) => {
  const [, { touched, error, initialValue }, { setValue, setTouched }] =
    useField({
      name,
    });

  const [imageUrl, setImageUrl] = useState(initialValue);

  const hasError = Boolean(touched) && Boolean(error);

  const handleLoadFile = e => {
    setTouched(true);
    const reader = new FileReader();
    const [file] = e.target.files;
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setImageUrl(reader.result);
    };
    setValue(file);
  };

  // const handleReset = () => {
  //   setValue(null);
  //   setImageUrl(null);
  // };

  useEffect(() => {
    setImageUrl(url);
  }, [url]);

  useEffect(() => {
    setImageUrl(initialValue);
  }, [initialValue]);

  return (
    <FormField style={{ width: 'auto' }}>
      <Stack gutterSize={0.5} direction="column">
        <Stack>
          <label style={{ cursor: 'pointer' }}>
            <Avatar imageUrl={imageUrl} size={size} icon={placeholder} />
            <input
              type="file"
              accept="image/*"
              onChange={handleLoadFile}
              onClick={e => {
                e.target.value = null;
              }}
              style={{ display: 'none' }}
            />
          </label>
        </Stack>

        <Stack direction="column" gutterSize={0.25}>
          {/* {Boolean(value) && (
                <Button type="button" onClick={handleReset}>
                  <IcBin size={24} color={theme.textLighter} />
                </Button>
              )} */}
        </Stack>
        <FormLabel>{label}</FormLabel>
        {hasError && <ErrorMessageWrapper>{error}</ErrorMessageWrapper>}
      </Stack>
    </FormField>
  );
};

export default AvatarField;
