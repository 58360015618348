const Share = props => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.25 2.56v8.69a.75.75 0 001.5 0V2.56l2.47 2.47a.75.75 0 101.06-1.06L12.53.22a.748.748 0 00-1.06 0L7.72 3.97a.75.75 0 001.06 1.06l2.47-2.47zM5.25 9a.75.75 0 00-.75.75v12a.75.75 0 00.75.75h13.5a.75.75 0 00.75-.75v-12a.75.75 0 00-.75-.75h-1.5a.75.75 0 010-1.5h1.5A2.25 2.25 0 0121 9.75v12A2.25 2.25 0 0118.75 24H5.25A2.25 2.25 0 013 21.75v-12A2.25 2.25 0 015.25 7.5h1.5a.75.75 0 010 1.5h-1.5z"
      fill="#fff"
    />
  </svg>
);

export default Share;
