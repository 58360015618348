import { createContext, useContext } from 'react';

export const AuthContext = createContext();

export const useAuth = () => {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }

  return context;
};

export const AppBannerContext = createContext();

export const useAppBanner = () => {
  const context = useContext(AppBannerContext);

  if (context === undefined) {
    throw new Error(`useAppBanner must be used within a AppBannerProvider`);
  }

  return context;
};

export const AppContext = createContext();

export const useApp = () => {
  const context = useContext(AppContext);

  if (context === undefined) {
    throw new Error(`useApp must be used within a AppProvider`);
  }

  return context;
};

export const LocaleContext = createContext();

export const useLocale = () => {
  const context = useContext(LocaleContext);

  if (context === undefined) {
    throw new Error(`useLocale must be used within a localeProvider`);
  }

  return context;
};

export const PlayerContext = createContext();

export const usePlayer = () => {
  const context = useContext(PlayerContext);

  if (context === undefined) {
    throw new Error(`usePlayer must be used within an PlayerProvider`);
  }

  return context;
};
