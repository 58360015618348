import { Input, LightWrapper, MainLight } from 'ui';
import { Button, Header } from 'components';
import { useApp } from 'hooks';
import { Spinner, Stack, Text } from '@tymate/margaret';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import AddPicture from 'components/Icons/Checkbox copy';
import { TextField } from 'components/Fields';
import { Form, Formik } from 'formik';
import Upload from 'components/Icons/Upload';
import Wave from 'components/Icons/Wave';
//import BlueCircle from 'components/Icons/BlueCircle';
//import AdminPlay from 'components/Icons/AdminPlay';
import { forwardRef, useState } from 'react';
//import AdminPause from 'components/Icons/AdminPause';
import {
  FileAmountLimitValidator,
  FileTypeValidator,
  FileSizeValidator,
  ImageDimensionsValidator,
} from 'use-file-picker/dist/validators';
import { useFilePicker } from 'use-file-picker/dist';
import { useMutation, useQuery } from '@apollo/client';
import { CREATE_AUDIO, UPDATE_AUDIO, GET_BO_AUDIO } from 'api/audio';
import { pickBy } from 'lodash';
import * as Yup from 'yup';
import AdminBackButton from 'components/AdminBackButton';
import { toast } from 'react-toastify';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { isBefore } from 'date-fns';
import Cross from 'components/Icons/Cross';

const Box = styled.div`
  background-color: ${({ theme }) => theme.backgroundWhite};
  border-radius: 4px;
  padding: 24px;
`;

const AdminAudio = () => {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const audioId = searchParams.get('audio');

  const { organization } = useApp();
  const { t } = useTranslation('adminAudio');
  const [isPlayingPreview, setIsPlayingPreview] = useState(false);
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [isCreateLoading, setIsCreateLoading] = useState(false);
  const [createAudio] = useMutation(CREATE_AUDIO);
  const [updateAudio] = useMutation(UPDATE_AUDIO);
  const navigate = useNavigate();

  const { data, loading } = useQuery(GET_BO_AUDIO, {
    variables: { audioId: audioId },
    skip: !Boolean(audioId),
  });

  const isPublished = audioId ? !data?.audio?.draft : false;

  const {
    openFilePicker: openPicturePicker,
    filesContent: selectedPicture,
    plainFiles: plainPicture,
    loading: pictureLoading,
    errors: pictureErrors,
  } = useFilePicker({
    readAs: 'DataURL',
    accept: 'image/*',
    multiple: true,
    validators: [
      new FileAmountLimitValidator({ max: 1 }),
      new FileTypeValidator(['jpg', 'jpeg', 'png']),
      new FileSizeValidator({ maxFileSize: 10 * 1024 * 1024 }),
      new ImageDimensionsValidator({
        maxHeight: 1400,
        maxWidth: 1400,
        minHeight: 400,
        minWidth: 400,
      }),
    ],
  });

  const {
    openFilePicker,
    filesContent: selectedFile,
    plainFiles,
    //loading: fileLoading,
    errors: fileErrors,
  } = useFilePicker({
    readAs: 'DataURL',
    accept: ['.mp3', '.wav'],
    multiple: true,
    validators: [
      new FileAmountLimitValidator({ max: 1 }),
      new FileSizeValidator({ maxFileSize: 500 * 1024 * 1024 }),
    ],
  });

  const audioCreator = async values => {
    if (audioId) {
      audioUpdatePublish(values);
      return;
    }

    setIsCreateLoading(true);
    try {
      const input = pickBy({
        file: plainFiles[0],
        title: values.audioTitle,
        podcastId: id,
        description: values?.description,
        cover: plainPicture[0],
        publishedAt: values?.date,
      });
      await createAudio({
        variables: {
          input: input,
        },
      });

      navigate(-1);
      toast.success(t('uploadSuccess'));
    } catch (err) {
      toast.error(t('uploadError'));
    }

    setIsCreateLoading(false);
  };

  const audioSave = async values => {
    if (audioId) {
      audioUpdateSave(values);
      return;
    }

    setIsSaveLoading(true);
    try {
      const input = pickBy({
        file: plainFiles[0],
        title: values.audioTitle,
        podcastId: id,
        description: values?.description,
        cover: plainPicture[0],
        draft: true,
        publishedAt: values?.date,
      });
      await createAudio({
        variables: {
          input: input,
        },
      });

      navigate(-1);
      toast.success(t('saveSuccess'));
    } catch (err) {
      toast.error(t('saveError'));
    }

    setIsSaveLoading(false);
  };

  const audioUpdateSave = async values => {
    setIsSaveLoading(true);
    try {
      const input = pickBy({
        file: plainFiles[0],
        title: values.audioTitle,
        description: values?.description,
        cover: plainPicture[0],
        draft: true,
        audioId: audioId,
        publishedAt: values?.date,
      });
      await updateAudio({
        variables: {
          input: input,
        },
      });

      navigate(-1);
      toast.success(t('saveSuccess'));
    } catch (err) {
      toast.error(t('saveError'));
    }

    setIsSaveLoading(false);
  };

  const audioUpdatePublish = async values => {
    setIsSaveLoading(true);
    try {
      const input = {
        ...pickBy({
          file: plainFiles[0],
          title: values.audioTitle,
          description: values?.description,
          cover: plainPicture[0],
          publishedAt: values?.date,
          audioId: audioId,
        }),
        draft: false,
      };
      await updateAudio({
        variables: {
          input: input,
        },
      });

      navigate(-1);
      toast.success(t('uploadSuccess'));
    } catch (err) {
      toast.error(t('uploadError'));
    }

    setIsSaveLoading(false);
  };

  const CustomDateInput = forwardRef(({ value, onClick }, ref) => (
    <Input
      onClick={onClick}
      ref={ref}
      value={value}
      light
      style={{ minWidth: 0 }}
    />
  ));

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      <Header customLogo={organization?.logo?.url} color="#060d0d" isAdmin />

      <MainLight>
        {isSaveLoading || isCreateLoading ? (
          <Stack direction="column" alignX="center">
            <Spinner />
            <Text color="textDark" fontWeight={700}>
              {t('uploadLoading')}
            </Text>
            <Text color="textDark">{t('dontLeave')}</Text>
          </Stack>
        ) : (
          <LightWrapper>
            <Formik
              initialValues={{
                audioTitle: data?.audio?.title ?? '',
                description: data?.audio?.description ?? '',
                date: data?.audio?.publishedAt
                  ? new Date(data?.audio?.publishedAt)
                  : null,
              }}
              validationSchema={Yup.object().shape({
                audioTitle: Yup.string().required(' '),
              })}
            >
              {({ values, setFieldValue }) => (
                <Form>
                  <Stack direction="column" gap={2}>
                    <Stack>
                      <AdminBackButton />
                    </Stack>
                    <Box>
                      <Stack gap={2} flex={1}>
                        <Stack direction="column" gap={1}>
                          {pictureErrors.length === 0 &&
                          selectedPicture.length > 0 ? (
                            <img
                              alt={selectedPicture[0].name}
                              src={selectedPicture[0].content}
                              onClick={() => openPicturePicker()}
                              style={{ width: 320, height: 320 }}
                            />
                          ) : audioId && data?.audio?.cover?.url ? (
                            <img
                              alt="cover"
                              src={data?.audio?.cover?.url}
                              onClick={() => openPicturePicker()}
                              style={{ width: 320, height: 320 }}
                            />
                          ) : (
                            <Button
                              style={{
                                width: 320,
                                height: 320,
                                backgroundColor: 'rgba(239, 245, 246, 1)',
                                borderRadius: 4,
                              }}
                              onClick={() => openPicturePicker()}
                            >
                              {pictureLoading ? (
                                <Spinner />
                              ) : (
                                <Stack
                                  direction="column"
                                  alignX="center"
                                  gap={1}
                                >
                                  <AddPicture />
                                  <Text color="textDark" fontWeight={700}>
                                    {t('addPicture')}
                                  </Text>
                                </Stack>
                              )}
                            </Button>
                          )}

                          {pictureErrors.length > 0 && (
                            <Text color="error">{t('pictureFormatError')}</Text>
                          )}

                          {pictureErrors.length === 0 &&
                            selectedPicture.length > 0 && (
                              <Text color="textDark" style={{ width: 320 }}>
                                {selectedPicture[0]?.name}
                              </Text>
                            )}

                          <Stack gap={0.5}>
                            <Text color="textDark" fontWeight={700}>
                              {t('format')}
                            </Text>
                            <Text color="textDark">
                              {t('pictureFormatInfo')}
                            </Text>
                          </Stack>
                        </Stack>
                        <Stack direction="column" gap={1} flex={1}>
                          <TextField
                            id="audioTitle"
                            placeholder={t('audioTitle')}
                            name="audioTitle"
                            light
                          />

                          <TextField
                            id="description"
                            placeholder={t('audioDescription')}
                            name="description"
                            light
                          />
                        </Stack>
                      </Stack>
                    </Box>

                    <Stack gap={4}>
                      <Box>
                        <Stack gap={2} alignY="center">
                          <Button
                            style={{
                              width: 280,
                              height: 100,
                              backgroundColor: 'rgba(239, 245, 246, 1)',
                              borderRadius: 4,
                            }}
                            onClick={() =>
                              setIsPlayingPreview(!isPlayingPreview)
                            }
                          >
                            <Stack>
                              <Wave />
                              {/*   {fileLoading ? (
                                <Spinner />
                              ) : (
                                fileErrors.length === 0 &&
                                selectedFile.length > 0 && (
                                  <>
                                    <BlueCircle
                                      style={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                      }}
                                    />
                                    {isPlayingPreview ? (
                                      <AdminPause
                                        style={{
                                          position: 'absolute',
                                          top: '50%',
                                          left: '50%',
                                          transform: 'translate(-50%, -50%)',
                                        }}
                                      />
                                    ) : (
                                      <AdminPlay
                                        style={{
                                          position: 'absolute',
                                          top: '50%',
                                          left: '50%',
                                          transform: 'translate(-40%, -50%)',
                                        }}
                                      />
                                    )}
                                  </>
                                )
                              )} */}
                            </Stack>
                          </Button>
                          <Stack gap={1} direction="column">
                            {selectedFile.length === 0 && !audioId && (
                              <Text color="textDark" fontWeight={700}>
                                {t('uploadEmpty')}
                              </Text>
                            )}
                            {fileErrors.length === 0 &&
                              selectedFile.length > 0 && (
                                <Text color="textDark" fontWeight={700}>
                                  {selectedFile[0]?.name}
                                </Text>
                              )}
                            {audioId && (
                              <Text color="textDark" fontWeight={700}>
                                audio_file.mp3
                              </Text>
                            )}

                            <Button
                              variant="adminPrimary"
                              onClick={() => openFilePicker()}
                            >
                              <Upload /> {t('uploadFile')}
                            </Button>
                            {fileErrors.length > 0 && (
                              <Stack>
                                <Text color="error">
                                  {t('uploadFormatError')}
                                </Text>
                              </Stack>
                            )}
                            <Stack gap={0.5}>
                              <Text color="textDark" fontWeight={700}>
                                {t('format')}
                              </Text>
                              <Text color="textDark">
                                {t('uploadFormatInfo')}
                              </Text>
                            </Stack>
                          </Stack>
                        </Stack>
                      </Box>

                      <Stack direction="column" gap={1} flex={1}>
                        <Text color="textDark" fontWeight={700}>
                          {t('date')}
                        </Text>
                        <Stack>
                          <ReactDatePicker
                            onChange={date => {
                              if (isBefore(date, new Date())) {
                                setFieldValue('date', new Date());
                              } else {
                                setFieldValue('date', date);
                              }
                            }}
                            value={values.date}
                            showTimeSelect
                            selected={values.date}
                            timeFormat="HH:mm"
                            dateFormat="dd/MM/yyyy HH:mm"
                            minDate={new Date()}
                            timeIntervals={10}
                            customInput={<CustomDateInput />}
                            style={{ width: 10 }}
                          />
                          {values.date && (
                            <Button onClick={() => setFieldValue('date', null)}>
                              <Cross />
                            </Button>
                          )}
                        </Stack>

                        <Stack
                          direction="row"
                          gap={1}
                          flex={1}
                          alignY="flex-end"
                        >
                          <Button
                            variant="adminPrimary"
                            onClick={() => {
                              audioCreator(values);
                            }}
                            loading={isCreateLoading}
                            disabled={
                              !(
                                fileErrors.length === 0 &&
                                selectedFile.length > 0
                              ) && !audioId
                            }
                          >
                            {t('publish')}
                          </Button>
                          {!isPublished && (
                            <Button
                              variant="adminSecondary"
                              onClick={() => audioSave(values)}
                              loading={isSaveLoading}
                              disabled={
                                !(
                                  fileErrors.length === 0 &&
                                  selectedFile.length > 0
                                ) && !audioId
                              }
                            >
                              {t('save')}
                            </Button>
                          )}
                        </Stack>
                      </Stack>
                    </Stack>

                    {/*  <Stack direction="column" gap={2}>
                    <Text color="textDark" fontWeight={700}>
                      {t('author')}
                    </Text>
                    <Stack gap={1}>
                      <TextField
                        id="author"
                        placeholder={t('name')}
                        name="author"
                        light
                      />
                      <Button variant="adminSecondary">
                        <AddPicture /> {t('addPicture')}
                      </Button>
                    </Stack>
                  </Stack> */}
                  </Stack>
                </Form>
              )}
            </Formik>
          </LightWrapper>
        )}
      </MainLight>
    </>
  );
};

export default AdminAudio;
