import { useTranslation } from 'react-i18next';
import Button from './Button';
import Back from './Icons/Back';
import { useNavigate } from 'react-router-dom';

const BackButton = () => {
  const { t } = useTranslation('misc');
  const navigate = useNavigate();

  return (
    <Button variant="text" icon={<Back />} onClick={() => navigate(-1)}>
      {t('back')}
    </Button>
  );
};

export default BackButton;
