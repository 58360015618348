const Add = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M13.6589 9.31268H5.513"
        stroke="white"
        stroke-width="1.08"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.58594 13.3856V5.23975"
        stroke="white"
        stroke-width="1.08"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <circle
        cx="9.39453"
        cy="9.25732"
        r="7.8975"
        stroke="white"
        stroke-width="1.08"
      />
    </svg>
  );
};

export default Add;
