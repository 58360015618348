const PlayForward = props => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ ...props.style, fontSize: props.size }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.82 13.489l2.96-2.962a.75.75 0 00-1.06-1.06l-1.768 1.768A10.494 10.494 0 0010.5 1.75C4.706 1.75 0 6.445 0 12.25c0 5.794 4.706 10.5 10.5 10.5a.75.75 0 000-1.5 9 9 0 110-18 8.994 8.994 0 018.93 7.866l-1.65-1.65a.75.75 0 10-1.06 1.061l2.96 2.962a.748.748 0 001.14 0z"
        fill={props.colorFill ?? '#000'}
        fillOpacity={props.fillOpacity ?? 0.38}
      />
      <path
        d="M6.967 15.516c1.404 0 2.268-.873 2.268-2.061v-.018c0-1.17-.837-1.62-1.692-1.764l1.233-1.179c.252-.243.414-.432.414-.774 0-.378-.297-.612-.702-.612H5.419a.6.6 0 00-.594.594.6.6 0 00.594.594h1.935L6.13 11.529c-.216.216-.297.378-.297.567a.6.6 0 00.594.594h.216c.774 0 1.233.288 1.233.801v.018c0 .468-.369.765-.891.765-.495 0-.873-.162-1.233-.468a.658.658 0 00-.441-.171.676.676 0 00-.666.666c0 .198.108.387.234.495.513.441 1.188.72 2.088.72zM12.534 15.516c1.683 0 2.808-1.431 2.808-3.258v-.018c0-1.827-1.125-3.24-2.79-3.24-1.674 0-2.808 1.431-2.808 3.258v.018c0 1.827 1.107 3.24 2.79 3.24zm.018-1.251c-.837 0-1.386-.855-1.386-2.007v-.018c0-1.152.54-1.989 1.368-1.989.828 0 1.386.864 1.386 2.007v.018c0 1.143-.531 1.989-1.368 1.989z"
        fill={props.colorFill ?? '#000'}
        fillOpacity={props.fillOpacity ?? 0.38}
      />
    </svg>
  );
};

export default PlayForward;
