import * as React from 'react';
const SoundIncrease = props => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ ...props.style, fontSize: props.size }}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.798 3.759A2.25 2.25 0 0116.25 6v12a2.25 2.25 0 01-3.575 1.819L6.282 15.75H3.5a2.25 2.25 0 01-2.25-2.25v-3A2.25 2.25 0 013.5 8.25h2.782l6.393-4.069a2.25 2.25 0 011.123-.422zm.538 1.57a.75.75 0 01.414.67V18a.75.75 0 01-1.2.6.765.765 0 00-.047-.033l-6.6-4.2a.75.75 0 00-.403-.117h-3a.75.75 0 01-.75-.75v-3a.75.75 0 01.75-.75h3a.75.75 0 00.403-.117l6.6-4.2a.759.759 0 00.047-.033.75.75 0 01.786-.071zM21.56 8.018a.75.75 0 011.035.229 7.35 7.35 0 010 7.906.75.75 0 01-1.264-.806 5.85 5.85 0 000-6.294.75.75 0 01.229-1.035zM18.59 9.454a.75.75 0 011.054.11 4.243 4.243 0 010 5.35.75.75 0 11-1.164-.946 2.743 2.743 0 000-3.46.75.75 0 01.11-1.054z"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.5 8.25a.75.75 0 01.75.75v6a.75.75 0 01-1.5 0V9a.75.75 0 01.75-.75z"
        fill="#fff"
      />
    </svg>
  );
};

export default SoundIncrease;
