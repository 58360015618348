import { Stack, Text } from '@tymate/margaret';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import CategoryCard from './CategoryCard';
import { formatDuration } from 'utils';
import Button from './Button';
import { IcTarget } from './Icons';

const PodcastsWrapper = styled(Stack).attrs({ size: 'full', paddingTop: 1 })`
  display: grid;
  column-gap: 20px;
  row-gap: 20px;
  grid-template-columns: repeat(2, 1fr);

  ${({ theme }) => theme.media.tablet`
    grid-template-columns: repeat(4, 1fr);
  `};

  ${({ theme }) => theme.media.desktop`
    grid-template-columns: repeat(4, 1fr);
  `};
`;

const PodcastsSection = ({ category, title, isChapter }) => {
  const { t } = useTranslation('podcastSection');
  const podcastCategroy = category?.podcasts?.edges || [];

  if (podcastCategroy.length === 0) {
    return null;
  }

  return (
    <Stack size="full" direction="column">
      <Stack alignX="space-between" justifyContent="start">
        <Text type="h2">{title}</Text>
        <Button variant="simple" to={'category/' + category.slug}>
          {t('seeAll')}
        </Button>
      </Stack>

      {isChapter && (
        <Text
          color="textLight"
          type="callout"
          style={{ paddingTop: '6px', fontSize: 14 }}
        >
          {t('audioLearningDescription')}
        </Text>
      )}

      <PodcastsWrapper>
        {podcastCategroy.map(({ node }) => (
          <Stack size="full" direction="column" key={node?.id}>
            <CategoryCard
              backgroundImage={node?.image?.url}
              withoutGradient
              to={`/podcast/${node?.id}`}
            />
            <Stack alignY="center" gap={1} paddingVertical={0.5}>
              <Text color="textCallout" type="callout">
                {isChapter
                  ? t('chapters', {
                      count: node?.audiosCount,
                    })
                  : t('episodes', {
                      count: node?.audiosCount,
                    })}
                {' • '}
                {formatDuration(node?.aggregateDurationTime, {
                  format: 'chapters',
                })}
              </Text>
              {node?.targeted && <IcTarget />}
            </Stack>
            <Text fontWeight={700}>{node?.title}</Text>
          </Stack>
        ))}
      </PodcastsWrapper>
    </Stack>
  );
};

export default PodcastsSection;
