const Back = props => {
  return (
    <svg
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width={24} height={24} rx={6} fill="#fff" fillOpacity={0.16} />
      <path
        d="m13.72 15.853-3.574-3.573a.5.5 0 0 1 0-.707L13.72 8"
        stroke="#fff"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Back;
