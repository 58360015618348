const Checkbox = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M16.8125 9.375L10.3958 15.5L7.1875 12.4375"
        stroke="#D2193C"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <rect
        x="1.625"
        y="1.625"
        width="20.75"
        height="20.75"
        rx="3.75"
        stroke="#D2193C"
        stroke-width="1.5"
      />
    </svg>
  );
};

export default Checkbox;
