import { Stack, Text } from '@tymate/margaret';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { getCategoryTitleKey } from 'api';
import { Link } from 'react-router-dom';

const BackgroundImageCard = styled(Stack).attrs({
  size: 'full',
  alignY: 'flex-end',
  paddingLeft: 1,
  paddingBottom: 1,
})`
  height: 145px;
  grid-area: ${({ gridArea }) => gridArea};
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 35.42%,
      rgba(0, 0, 0, 0.85) 100%
    ),
    url('${({ backgroundImage }) => backgroundImage}');
  background-size: cover;
  border-radius: 12px;
  margin: 0;
  text-decoration: none;
  color: inherit;

  ${({ withoutGradient }) =>
    withoutGradient &&
    css`
      background-image: url('${({ backgroundImage }) => backgroundImage}');
    `}
`;

const CategoryCard = ({
  category,
  backgroundImage,
  withTitle,
  gridArea,
  title,
  to,
  withoutGradient = false,
}) => {
  const { t } = useTranslation('home');

  return (
    <BackgroundImageCard
      backgroundImage={backgroundImage}
      gridArea={gridArea}
      as={Link}
      to={to}
      withoutGradient={withoutGradient}
    >
      {withTitle && (
        <Stack style={{ width: '150px' }}>
          <Text type="body" fontWeight={700}>
            {category ? t(getCategoryTitleKey(category?.node?.slug)) : title}
          </Text>
        </Stack>
      )}
    </BackgroundImageCard>
  );
};

export default CategoryCard;
